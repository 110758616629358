import {html} from '@isceco/widget-library2/external/lit';

export default class KriteriumConfig {

  static get(code) {
    return this.getKriterien()
      .filter(k => k.code.toString() === code.toString())[0]
  }

  static getKriterien() {
    return [
      ...this.getWohnstandortKriterien(),
      ...this.getWohnanlageKriterien(),
      ...this.getWohnungenKriterien()
    ]
  }

  static getWohnstandortKriterien() {
    return [
      KriteriumConfig.K1(),
      KriteriumConfig.K2(),
      KriteriumConfig.K3(),
      KriteriumConfig.K4(),
      KriteriumConfig.K5(),
      KriteriumConfig.K6()
    ]
  }

  static getWohnanlageKriterien() {
    return [
      KriteriumConfig.K7(),
      KriteriumConfig.K8(),
      KriteriumConfig.K9(),
      KriteriumConfig.K10(),
      KriteriumConfig.K11(),
      KriteriumConfig.K12(),
      KriteriumConfig.K13(),
      KriteriumConfig.K14()
    ]
  }

  static getWohnungenKriterien() {
    return [
      KriteriumConfig.K15(),
      KriteriumConfig.K16(),
      KriteriumConfig.K17(),
      KriteriumConfig.K18(),
      KriteriumConfig.K19(),
      KriteriumConfig.K20(),
      KriteriumConfig.K21(),
      KriteriumConfig.K22(),
      KriteriumConfig.K23(),
      KriteriumConfig.K24(),
      KriteriumConfig.K25()
    ]
  }

  static K1() {
    return {
      code: 1,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k1.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k1.innovation.modal.description')}
        `
      },
      faq: 1
    }
  }

  static K2() {
    return {
      code: 2,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k2.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k2.innovation.modal.description')}
        `
      }
    }
  }

  static K3() {
    return {
      code: 3,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k3.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k3.innovation.modal.description')}
        `
      }
    }
  }

  static K4() {
    return {
      code: 4,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k4.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k4.innovation.modal.description')}
        `
      }
    }
  }

  static K5() {
    return {
      code: 5,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k5.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k5.innovation.modal.description')}
        `
      }
    }
  }

  static K6() {
    return {
      code: 6,
      potenzial: true,
      potenzialFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k6.potenzial.modal.description')}
        `
      },
      qualitaeten: 4,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k6.innovation.modal.description')}
        `
      }
    }
  }

  static K7() {
    return {
      code: 7,
      qualitaeten: 6,
      qualitaetenFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k7.qualitaet.modal.description')}
        `
      },
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k7.innovation.modal.description')}
        `
      }
    }
  }

  static K8() {
    return {
      code: 8,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k8.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k8.innovation.modal.description')}
        `
      },
      faq: 1
    }
  }

  static K9() {
    return {
      code: 9,
      qualitaeten: 6,
      qualitaetenFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k9.qualitaet.modal.description')}
        `
      },
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k9.innovation.modal.description')}
        `
      }
    }
  }

  static K10() {
    return {
      code: 10,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k10.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k10.innovation.modal.description')}
        `
      },
      faq: 1
    }
  }

  static K11() {
    return {
      code: 11,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k11.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k11.innovation.modal.description')}
        `
      },
      faq: 1
    }
  }

  static K12() {
    return {
      code: 12,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k12.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k12.innovation.modal.description')}
        `
      },
      faq: 1
    }
  }

  static K13() {
    return {
      code: 13,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k13.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k13.innovation.modal.description')}
        `
      }
    }
  }

  static K14() {
    return {
      code: 14,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetImage: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k14.quantitaet.modal.description')}
        `
      },
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k14.innovation.modal.description')}
        `
      },
      faq: 2
    }
  }

  static K15() {
    return {
      code: 15,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k15.quantitaet.modal.description')}
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k15.innovation.modal.description')}
        `
      },
      qualityEditableLogic: true,
      faqGrundausstattung: true
    }
  }

  static K16() {
    return {
      code: 16,
      quantitaet: [0, 1, 2, 3],
      quantitaetEditable: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k16.quantitaet.modal.description')}
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k16.innovation.modal.description')}
        `
      },
      faq: 3,
      faqGrundausstattung: true
    }
  }

  static K17() {
    return {
      code: 17,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k17.quantitaet.modal.description')}
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image" src="./images/${window.getLanguage()}/k17/modal.gif"/>
          </div>
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k17.innovation.modal.description')}
        `
      },
      faq: 1,
      faqGrundausstattung: true,
      faqSchablone: true
    }
  }

  static K18() {
    return {
      code: 18,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k18.quantitaet.modal.description')}
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image" src="./images/${window.getLanguage()}/k18/modal.gif"/>
          </div>
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k18.innovation.modal.description')}
        `
      },
      faq: 3,
      faqGrundausstattung: true,
      faqSchablone: true
    }
  }

  static K19() {
    return {
      code: 19,
      qualitaeten: 6,
      qualitaetenFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k19.qualitaet.modal.description')}
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image" src="./images/${window.getLanguage()}/k19/modal.gif"/>
          </div>
        `
      },
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k19.innovation.modal.description')}
        `
      },
      faq: 1,
      faqGrundausstattung: true,
      faqSchablone: true
    }
  }

  static K20() {
    return {
      code: 20,
      quantitaet: [0, 1, 2, 3],
      quantitaetEditable: true,
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k20.quantitaet.modal.description')}
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k20.innovation.modal.description')}
        `
      },
      faq: 2,
      faqGrundausstattung: true
    }
  }

  static K21() {
    return {
      code: 21,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k21.quantitaet.modal.description')}
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image" src="./images/${window.getLanguage()}/k21/modal.gif"/>
          </div>
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k21.innovation.modal.description')}
        `
      },
      faqGrundausstattung: true,
      faqSchablone: true
    }
  }

  static K22() {
    return {
      code: 22,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k22.quantitaet.modal.description')}
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image" src="./images/${window.getLanguage()}/k22/modal.gif"/>
          </div>
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k22.innovation.modal.description')}
        `
      },
      faqGrundausstattung: true
    }
  }

  static K23() {
    return {
      code: 23,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k23.quantitaet.modal.description')}
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k23.innovation.modal.description')}
        `
      },
      qualityEditableLogic: true,
      faqGrundausstattung: true
    }
  }

  static K24() {
    return {
      code: 24,
      qualitaeten: 6,
      qualitaetenFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k24.qualitaet.modal.description')}
        `
      },
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k24.innovation.modal.description')}
        `
      },
      innovation: true,
      faq: 2,
      faqGrundausstattung: true
    }
  }

  static K25() {
    return {
      code: 25,
      quantitaet: [0, 1, 1.5, 2, 2.5, 3],
      quantitaetFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k25.quantitaet.modal.description')}
        `
      },
      innovationFurtherInfoContent: i18n => {
        return html`
          ${i18n.translate('k25.innovation.modal.description')}
        `
      },
      quantitaetImage: true,
      qualitaeten: 3,
      innovation: true,
      faqGrundausstattung: true
    }
  }
}

import {html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import KontaktService from '../Kontakt/KontaktService.js';
import MainWebComponent from '../../MainWebComponent.js'

export default class Kontakt extends MainWebComponent {
  get translationFile() {
    return './views/Kontakt/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.header')}"></isceco-title>
      <p>
        ${this.i18n.translate('page.description')}
      </p>
      <p>
        ${this.i18n.translate('durckversion.text')}
        <isceco-link text="${this.i18n.translate('durckversion.link.text')}"
                     url="${this.i18n.translate('durckversion.link.href')}">
        </isceco-link>
        .
      </p>
      <p>
        ${this.i18n.translate('adresse.name')}<br/>
        ${this.i18n.translate('adresse.strasse')}<br/>
        ${this.i18n.translate('adresse.ort')}<br/>
        <br/>
        ${this.i18n.translate('adresse.telefon')}<br/>
        <isceco-link text="${this.i18n.translate('adresse.email')}" url="mailto:wbs@bwo.admin.ch" target="_blank">
          ${this.i18n.translate('adresse.email')}
        </isceco-link>
        <br/>
      </p>
      <isceco-form id="kontakt-form"
                   submit-button-text="${this.i18n.translate('kontakt.form.speichern')}"
                   @submit="${e => this.submit(e.detail)}">
        <div slot="form-elements" class="form">
          <isceco-textarea name="nachricht"
                           required
                           label="${this.i18n.translate('kontakt.form.nachricht')}"
                           rows="10"
          ></isceco-textarea>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${this.i18n.translate('kontakt.form.abbrechen')}"
                         @click="${_ => this.cancel()}"></isceco-button>
        </div>
      </isceco-form>

      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.back(e)}"
      >
      </isceco-dialog>
    `
  }

  cancel = _ => {
    if (formChanged('#kontakt-form')) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.back()
    }
  }

  back = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    navigate({to: 'Wohnobjekt'})
  }

  submit = kontakt => {
    const kontaktService = new KontaktService()
    kontaktService.create(kontakt)
      .then(json => {
        showAlert(this.i18n.translate('kontakt.sent.success.title'), this.i18n.translate('kontakt.sent.success.body'), 'success')
        navigate({to: '', id: json.id})
      })
  }
}

customElements.define('tool-frontend-kontakt', Kontakt)

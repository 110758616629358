import {html, LitElement} from '@isceco/widget-library2/external/lit'
import TooltipCSS from './TooltipCSS.js'

export class Tooltip extends LitElement {

  constructor() {
    super()
    this.reset()
    this.distanceFromEdge = 110
  }

  static get styles() {
    return [TooltipCSS]
  }

  static get properties() {
    return {}
  }

  render() {
    return html`
      <div id="container"
           hidden
           style="top: ${this.ypos}px; left: ${this.xpos}px;">
        <div class="triangle" style="border-color: ${this.color};"></div>
        <div class="content" style="border-color: ${this.color}; margin-left: ${this.xoffset}px; margin-right: ${-this.xoffset}px;">
          <b>${this.title}</b><br>
          ${this.text}
        </div>
      </div>
    `
  }

  reset = () => {
    this.color = ''
    this.title = ''
    this.text = ''
    this.xpos = 0
    this.xoffset = 0
    this.ypos = 0
  }

  show = (color, title, text, element) => {
    this.color = color
    this.title = title
    this.text = text
    this.xpos = element.getBoundingClientRect().x
    if (this.xpos < this.distanceFromEdge) {
      this.xoffset = this.distanceFromEdge - this.xpos
    }
    if (this.xpos > window.innerWidth - this.distanceFromEdge) {
      this.xoffset = window.innerWidth - this.distanceFromEdge - this.xpos
    }
    this.ypos = element.getBoundingClientRect().y
    this.shadowRoot.querySelector('#container').hidden = false
    this.requestUpdate()
  }

  hide = () => {
    this.shadowRoot.querySelector('#container').hidden = true
    this.reset()
    this.requestUpdate()
  }
}

customElements.define('tool-frontend-tooltip', Tooltip)

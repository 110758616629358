import '@isceco/widget-library2/index.js'
import '@isceco/widget-library2/core.css'
import './views/Header/Header.js'
import './views/Message/Message.js'
import './views/Navigation/Navigation.js'
import './views/Navigation/HorizontalNavigation.js'
import './views/MainView/MainView.js'
import './views/Footer/Footer.js'
import './views/Breadcrumb/Breadcrumb.js'

window.iscecoWidgetLibrary.iconCss = 'https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.8/dist/components/icon.min.css'
window.iscecoWidgetLibrary.languageKey = 'tool-frontend'
window.iscecoWidgetLibrary.supportedLanguages = ['de', 'fr', 'it', 'en']

if (!window.formChanged) {
  window.formChanged = (selector, object = {}) => {
    const form = document.querySelector(selector)
    const values = form.getValues()

    for (const [name, value] of Object.entries(values)) {
      // value not empty, but missing in source object
      if (value !== null && !object.hasOwnProperty(name)) {
        return true
      }
      // if form value is empty (null), allow corresponding object value to be undefined, null, ''
      // or missing in source object
      // values don't match
      if (object.hasOwnProperty(name) && value !== object[name] && (value !== null || !isEmpty(object[name]))) {
        return true
      }
    }
    return false
  }
}

export default class WohnobjektDTO {
  constructor(json) {
    if (json) {
      this.id = json.id
      this.erstellt = json.erstellt
      this.titel = json.titel
      this.plz = json.plz
      this.standort = json.standort
      this.anzahlGeteiltMit = json.anzahlGeteiltMit > 0 ? json.anzahlGeteiltMit : null
    } else {
      this.json = {}
    }
  }
}

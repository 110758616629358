import BaseService from '../../BaseService.js';

export default class WohnobjektService extends BaseService {

  constructor() {
    super('wohnobjekte')
  }

  async latest() {
    return this.read('latest')
  }
}

import ExceptionService from './ExceptionService.js';
import ApplicationException from './ApplicationException';

export default class BaseService {

  constructor(relativeBase) {
    this.backendUrl = ''
    this.relativeBase = relativeBase
    this.logging = new ExceptionService()
  }

  retrieveConfiguration() {
    return fetch('./config/config.json', {cache: 'reload'})
      .then(res => res.json())
  }

  retrieveBackendUrl() {
    return this.retrieveConfiguration()
      .then(config => {
        this.backendUrl = `${config.backendUrl}/${this.relativeBase}`
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  async getBackendListUrl(serviceName) {
    await this.retrieveBackendUrl()
    return serviceName ? `${this.backendUrl}/${serviceName}` : this.backendUrl
  }

  async list(serviceName, foreceResult = false) {
    await this.getBackendListUrl(serviceName).then(url => this.serviceurl = url)
    return this.checkError(
      fetch(this.serviceurl, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      }),
      foreceResult
    )
  }

  async create(object, commandSuffix = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(this.backendUrl + commandSuffix, {
        method: 'POST',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      })
    )
  }

  async read(id, subResource = '', foreceResult = false) {
    await this.retrieveBackendUrl()
    const subResourcePath = isEmpty(subResource) ? '' : `/${subResource}`
    return this.checkError(
      fetch(`${this.backendUrl}/${id}${subResourcePath}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      }),
      foreceResult
    )
  }

  async update(object, serviceName) {
    await this.retrieveBackendUrl()
    const url = (serviceName) ? `${this.backendUrl}/${serviceName}` : this.backendUrl
    return this.checkError(
      fetch(`${url}/${object.id}`, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      })
    )
  }

  async delete(id) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${id}`, {
        method: 'DELETE',
        cache: 'reload',
        headers: {
          'Authorization': 'Bearer ' + window.keycloak.token,
          'locale': getLanguage()
        }
      })
    )
  }

  async checkError(promise, forceResult = false) {
    return promise
      .then(result => {
        if (result.ok) {
          const contentType = result.headers.get('content-type')
          if (!forceResult && contentType && contentType.indexOf('application/json') !== -1) {
            return result.json()
          } else {
            return result
          }
        } else {
          result.json().then(err => showAlert(err.title, err.detail, 'error', err.reasons))
          throw new ApplicationException()
        }
      })
      .catch(error => {
        if (error instanceof ApplicationException) {
          throw error
        } else {
          showAlert('Service unavailable', 'Request to backend could not be sent', 'error')
          this.logging.log(error)
        }
      })
  }

  getJsonHeader() {
    return {
      'Authorization': 'Bearer ' + window.keycloak.token,
      'Content-Type': 'application/json',
      'locale': getLanguage()
    }
  }
}

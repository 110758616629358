import {css} from '@isceco/widget-library2/external/lit'

export default css`
  :host {
    display: block;
    pointer-events: none;
  }

  #container {
    font-size: 0.8rem;
    margin-top: var(--isceco-space-200);
    position: fixed;
    z-index: 99;
    transform: translate(-50%, 0);
    width: 200px;
  }

  .triangle {
    border-left: var(--isceco-space-200) solid transparent !important;
    border-right: var(--isceco-space-200) solid transparent !important;
    border-bottom: var(--isceco-space-200) solid var(--isceco-color-white-500);
    width: 0;
    height: 0;
    margin-left: calc(50% - var(--isceco-space-200));
  }

  .content {
    border: 2px solid;
    background-color: var(--isceco-color-white-500);
    padding: var(--isceco-space-100);
    border-radius: var(--isceco-space-100);
    overflow-y: auto;
  }
`

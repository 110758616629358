import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Card/Card.js'
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import KriteriumService from '../Kriterium/KriteriumService.js'
import KriteriumListService from '../Kriterium/KriteriumListService.js'
import KriteriumDTO from '../Kriterium/KriteriumDTO.js'
import WohnobjektDetailColumns from './WohnobjektDetailColumns.js'
import WohnungenService from '../WohnungenEdit/WohnungenService.js'
import '../../components/SpiderPlot/SpiderPlot.js'
import '../../components/Tooltip/Tooltip.js'
import MainWebComponent from '../../MainWebComponent.js'
import Authorization from '../Authorization/Authorization.js';
import ExportService from './ExportService.js'
import GesuchService from '../Gesuche/GesuchService.js';
import WohnobjektDetailCss from './WohnobjektDetailCss.js'
import Freigabestatus from '../Gesuche/Freigabestatus.js'

export default class WohnobjektDetail extends MainWebComponent {

  get translationFile() {
    return './views/WohnobjektDetail/i18n.json'
  }

  get css() {
    return WohnobjektDetailCss
  }

  connectedCallback() {
    super.connectedCallback()
    this.kriterien = []
    this.wohnobjekt = {}
    this.total = {}
    this.wohnobjektId = ''
    this.wohnungen = []
    this.wohnungKriterien = []
    this.wohnungenTotal = []
    this.reload()
  }

  reload() {
    this.wohnobjektId = getNavigationId()
    const wohnobjektService = new WohnobjektService()
    const kriteriumService = new KriteriumService(this.wohnobjektId)
    Promise.all([kriteriumService.list(), wohnobjektService.read(this.wohnobjektId), wohnobjektService.read(this.wohnobjektId, 'wohnungen', true)])
      .then(([kriterienResult, wohnobjekt, wohnungenResult]) => {
        Promise.all([kriterienResult.json(), wohnungenResult.json()])
          .then(([kriterien, wohnungen]) => {
            this.kriterien = kriterien
            this.wohnobjekt = wohnobjekt
            this.wohnungen = wohnungen
            this.anzahlWohnungstypen = wohnungenResult.headers.get('X-count')
            this.anzahlWohnungen = wohnungen.reduce((n, {anzahl}) => n + anzahl, 0)
            this.total.wohnstandort = kriterienResult.headers.get('X-total-wohnstandort')
            this.total.wohnanlage = kriterienResult.headers.get('X-total-wohnanlage')
            this.total.wohnungen = kriterienResult.headers.get('X-total-wohnungen')
            this.total.total = kriterienResult.headers.get('X-total')
            this.colors = this.getColors()
            if (this.wohnungen.length > 0) {
              this.wohnungen.forEach(w => {
                new KriteriumService(this.wohnobjektId, w.id).list().then(wohnungenKriterienResult => {
                    wohnungenKriterienResult.json().then(wohnungenKriterien => {
                      this.wohnungenTotal[w.id] = wohnungenKriterienResult.headers.get('X-total')
                      this.wohnungKriterien[w.id] = wohnungenKriterien
                      this.render()
                    })
                  }
                )
              })
            } else {
              this.render()
            }
          })
      })
  }

  getTemplate() {
    const nutzungsanliegen = this.i18n.translate(`nutzungsanliegen.${this.wohnobjekt.nutzungsanliegen}`)
    const nutzungsgruppe = this.i18n.translate(`nutzungsgruppe.${this.wohnobjekt.nutzungsgruppe}`)
    return html`
      <div class="inline-reverse">
        <div class="column right">
          ${this.renderGesuchEinreichen()}
          ${this.renderGesuchEntscheiden()}
          <isceco-card text="${this.i18n.translate('wohnobjekt.standort')}">
            <span id="wohnobjekt-plz">${this.wohnobjekt.plz}</span>
            <span id="wohnobjekt-standort">${this.wohnobjekt.standort}</span>
            <br>
            ${this.i18n.translate('wohnobjekt.standort.anzahl.wohnungstypen')}:
            <span id="wohnobjekt-anzahl-wohnungstypen">${this.anzahlWohnungstypen}</span>
            <br>
            ${this.i18n.translate('wohnobjekt.standort.anzahl.wohnungen')}:
            <span id="wohnobjekt-anzahl-wohnungen">${this.anzahlWohnungen}</span>
          </isceco-card>

          <isceco-card text="${this.i18n.translate('wohnobjekt.nutzung')}">
            ${this.i18n.translate('wohnobjekt.nutzung.nutzungsanliegen')}:
            <span id="wohnobjekt-nutzungsanliegen">${nutzungsanliegen}</span>
            <br>
            ${this.i18n.translate('wohnobjekt.nutzung.nutzungsgruppe')}:
            <span id="wohnobjekt-nutzungsgruppe">${nutzungsgruppe}</span>
          </isceco-card>

          <isceco-title size="small" text="${this.i18n.translate('wohnobjekt.gebrauchswert.graph')}">
            <isceco-button variant="tertiary"
                           size="small"
                           icon="download"
                           text="${this.i18n.translate('wohnobjekt.gebrauchswert.graph.download')}"
                           @click="${_ => this.downloadSVG()}"
            ></isceco-button>
          </isceco-title>
          <tool-frontend-spider-plot id="spider-plot"
                                     .data="${this.kriterien}"
                                     center-text="${this.total.total}"
                                     .colors="${this.colors}"
                                     @tooltip="${e => this.showTooltip(e.detail)}"
          ></tool-frontend-spider-plot>

          <tool-frontend-tooltip id="gebrauchswert-tooltip"></tool-frontend-tooltip>
        </div>

        <div class="column left">
          <isceco-title text="${this.wohnobjekt.titel}">
            <isceco-action-menu overlap .items="${[
              {
                icon: 'pencil',
                text: this.i18n.translate('wohnobjekt.action.bearbeiten'),
                url: `WohnobjektEdit/${this.wohnobjektId}`
              },
              {
                icon: 'download',
                text: this.i18n.translate('wohnobjekt.action.download'),
                callback: _ => this.downloadPDF()
              },
              {
                icon: 'key',
                text: this.i18n.translate('wohnobjekt.action.berechtigungen'),
                url: `Berechtigung/${this.wohnobjektId}`,
                disabled: this.wohnobjekt.berechtigung !== Authorization.RIGHTS.admin || this.isGesuch()
              },
              {
                icon: 'history',
                text: this.i18n.translate('wohnobjekt.action.gesuchsverlauf'),
                url: `GesuchVerlauf/${this.wohnobjektId}`,
                disabled: this.wohnobjekt.nutzungsanliegen !== 4 || this.wohnobjekt.berechtigung !== Authorization.RIGHTS.admin || this.isGesuch()
              },
              {
                icon: 'trash alternate',
                text: this.i18n.translate('wohnobjekt.action.loeschen'),
                variant: 'danger',
                callback: _ => this.requestDelete(),
                disabled: this.wohnobjekt.berechtigung !== Authorization.RIGHTS.admin || this.isGesuch()
              }
            ]}">
              <isceco-button slot="wrapper" variant="tertiary" size="small" icon="ellipsis vertical"></isceco-button>
            </isceco-action-menu>
          </isceco-title>

          <p class="description">
            ${this.getBeschreibung()}
          </p>

          ${this.renderKrieterien('wohnstandort', 1, 6, WohnobjektDetailColumns.getWohnstandortColumns(this.wohnobjekt.id))}
          ${this.renderKrieterien('wohnanlage', 7, 14, WohnobjektDetailColumns.getWohnanlageColumns(this.wohnobjekt.id))}
          ${this.renderKrieterien('wohnungen', 15, 25, WohnobjektDetailColumns.getWohnungstypDurchschnittColumns())}

          <div class="total total-gesamt">
            <b>${this.i18n.translate('wohnobjekt.gebrauchswert')}</b>
            <b id="gebrauchswert">${parseFloat(this.total.total).toFixed(1)}</b>
          </div>
        </div>
      </div>

      ${this.wohnungen.map(wohnungen => this.renderWohnung(wohnungen))}

      <isceco-dialog hidden
                     id="wohnobjekt-loeschen-dialog"
                     header="${this.i18n.translate('wohnobjekt.loeschen.dialog.header')}"
                     confirm-button="${this.i18n.translate('wohnobjekt.loeschen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('wohnobjekt.loeschen.dialog.button.cancel')}"
                     @submit="${e => this.delete(e)}"
      ></isceco-dialog>

      <isceco-dialog hidden
                     id="wohnungen-loeschen-dialog"
                     header="${this.i18n.translate('wohnungen.loeschen.dialog.header')}"
                     confirm-button="${this.i18n.translate('wohnungen.loeschen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('wohnungen.loeschen.dialog.button.cancel')}"
                     @submit="${e => this.deleteWohnungen(e)}"
      ></isceco-dialog>
    `
  }

  renderGesuchEinreichen() {
    if (this.wohnobjekt.nutzungsanliegen === 4 && this.wohnobjekt.berechtigung === Authorization.RIGHTS.admin) {
      const datumElement = document.createElement('span')
      datumElement.style.display = 'block'
      const gesuchService = new GesuchService()
      gesuchService.read(`letztes?wohnobjektId=${this.wohnobjektId}`, '').then(gesuch => {
        const dateText = isEmpty(gesuch.eingereicht) ? this.i18n.translate('wohnobjekt.gesuch.zuletzt.nie') : formatDateTime(gesuch.eingereicht)
        datumElement.innerText = `${this.i18n.translate('wohnobjekt.gesuch.zuletzt')} ${dateText}`
      })
      return html`
        <isceco-card text="${this.i18n.translate('wohnobjekt.gesuch.titel')}">
          ${this.i18n.translate('wohnobjekt.gesuch.einreichen.beschreibung')}<br /><br />
          ${datumElement}<br/>
          <isceco-button variant="primary"
                         size="small"
                         id="gesuch-einreichen"
                         text="${this.i18n.translate('wohnobjekt.gesuch.einreichen')}"
                         @click="${_ => this.gesuchEinreichen()}"
                         icon="paper plane"
          ></isceco-button>
        </isceco-card>`
    }
    return html``
  }

  renderGesuchEntscheiden() {
    if (!this.isGesuch() || this.wohnobjekt.gesuchStatus !== Freigabestatus.KEYS.IN_BEARBEITUNG) {
      return html``
    }
    return html`
        <isceco-card text="${this.i18n.translate('wohnobjekt.gesuch.titel')}">
          <isceco-button variant="primary"
                         size="small"
                         id="gesuch-entscheiden"
                         text="${this.i18n.translate('gesuch.entscheiden')}"
                         @click="${_ => this.gesuchEntscheiden()}"
                         icon="tasks"
          ></isceco-button>
        </isceco-card>`
  }

  renderWohnung(wohnungen) {
    return html`
      <div class="inline-reverse wohnungen">
        <div class="column right">
          <isceco-card text="${this.i18n.translate('wohnungen.typ')} (${wohnungen.typ})">
            ${this.i18n.translate('wohnungen.anzahl')}:
            <span id="wohnungen-${wohnungen.id}-anzahl">${wohnungen.anzahl}</span>
            <br>
            ${this.i18n.translate('wohnungen.zimmer.anzahl')}:
            <span id="wohnungen-${wohnungen.id}-anzahl-zimmer">${wohnungen.anzahlZimmer}</span>
            <br>
            ${this.i18n.translate('wohnungen.nettowohnflaeche')}:
            <span id="wohnungen-${wohnungen.id}-nettowohnflaeche">${wohnungen.nettoWohnflaeche}</span>
          </isceco-card>
        </div>
        <div class="column left">
          ${this.renderWohnungKriterien(wohnungen.typ, 15, 25, WohnobjektDetailColumns.getWohnungstypColumns(this.wohnobjekt.id, wohnungen.id), wohnungen)}
        </div>
      </div>
    `
  }

  renderWohnungKriterien = (name, min, max, columns, wohnungen) => {
    if (!this.hasKriterien(min, max) || isEmpty(this.wohnungKriterien[wohnungen.id]) || isEmpty(this.wohnungenTotal[wohnungen.id])) {
      return ''
    }

    const kriteriumListService = new KriteriumListService(this.wohnungKriterien[wohnungen.id], min, max)
    return html`
      <isceco-title size="medium" text="${name}" id="wohnungen-${wohnungen.id}-typ">
        <isceco-action-menu overlap .items="${[
          {
            icon: 'pencil',
            text: this.i18n.translate('wohnungen.action.bearbeiten'),
            url: this.wohnungenBearbeiten(wohnungen.id)
          },
          {
            icon: 'trash alternate',
            text: this.i18n.translate('wohnungen.action.loeschen'),
            variant: 'danger',
            callback: _ => this.requestWohungenDelete(wohnungen),
            disabled: this.isGesuch()
          }
        ]}">
          <isceco-button slot="wrapper" variant="tertiary" size="small" icon="ellipsis vertical"></isceco-button>
        </isceco-action-menu>
      </isceco-title>

      <isceco-list id="wohnungen-${wohnungen.id}-table"
                   class="wohnung"
                   identifier="${name}"
                   pagination="off"
                   .i18n="${this.i18n}"
                   .listService="${kriteriumListService}"
                   .dtoMapper="${this.toKriterium}"
                   .columns="${columns}"
                   .mobileView="${this.getMobileView('wohnungen')}"
                   .mobileCss="${this.getMobileViewCss()}"
      ></isceco-list>
    `
  }

  renderKrieterien = (name, min, max, columns) => {
    const titleText = this.i18n.translate(`${name}.header`)

    let color
    let slot = html``
    if (min === 1) {
      color = 'wohnstandort'
    } else if (min === 7) {
      color = 'wohnanlage'
      if (!this.hasKriterien(min, max)) {
        slot = html`
          <isceco-button variant="tertiary"
                         id="wohnanlage-erstellen"
                         text="${this.i18n.translate('wohnanlage.erstellen')}"
                         @click="${_ => this.wohnanlageErstellen()}"
                         size="small"
                         icon="plus"
          ></isceco-button>`
      } else {
        slot = html`
          <isceco-button variant="tertiary"
                         id="wohnanlage-bearbeiten"
                         size="small"
                         icon="pencil"
                         text="${this.i18n.translate('wohnanlage.bearbeiten')}"
                         @click="${_ => this.wohnanlageBearbeiten()}"
          ></isceco-button>`
      }
    } else {
      color = 'wohnung'
      slot = html`
        <isceco-button variant="tertiary"
                       id="wohnungen-erstellen"
                       size="small"
                       icon="plus"
                       text="${this.i18n.translate('wohnungstyp.erstellen')}"
                       ?disabled="${this.isGesuch()}"
                       @click="${_ => this.wohnungenErstellen()}"
        ></isceco-button>
      `
    }

    if (!this.hasKriterien(min, max)) {
      return html`
        <isceco-title size="medium" text="${titleText}">
          ${slot}
        </isceco-title>
      `
    }

    const kriteriumListService = new KriteriumListService(this.kriterien, min, max)
    const gebrauchswertDescription = this.i18n.translate(`${name}.gebrauchswert`)
    const gebrauchswert = parseFloat(this.total[name]).toFixed(1)

    return html`
      <isceco-title size="medium" text="${titleText}">
        ${slot}
      </isceco-title>

      <isceco-list id="${name}-table"
                   class="${color}"
                   identifier="${name}"
                   pagination="off"
                   .i18n="${this.i18n}"
                   .listService="${kriteriumListService}"
                   .dtoMapper="${this.toKriterium}"
                   .columns="${columns}"
                   .mobileView="${this.getMobileView(name)}"
                   .mobileCss="${this.getMobileViewCss()}"
      ></isceco-list>
      <div class="total ${name}-total">
        <b>${gebrauchswertDescription}</b>
        <b id="${name}-gebrauchswert">${gebrauchswert}</b>
      </div>
    `
  }

  hasKriterien = (min, max) => this.kriterien.map(k => k.code).filter(k => k <= max && k >= min).length > 0

  toKriterium = row => new KriteriumDTO(row)

  gesuchEinreichen = () => {
    navigate({to: 'GesuchEinreichung', id: this.wohnobjektId})
  }

  gesuchEntscheiden = () => {
    navigate({to: 'GesuchEntscheidung', id: this.wohnobjektId})
  }

  wohnungenErstellen() {
    navigate({to: 'WohnungenEdit', params: {wohnobjekt: this.wohnobjektId, redirectUri: window.location.href}})
  }

  wohnungenBearbeiten = wohnungenId => {
    const params = new URLSearchParams({wohnobjekt: this.wohnobjektId, redirectUri: window.location.href})
    return `WohnungenEdit/${wohnungenId}?${params.toString()}`
  }

  wohnanlageErstellen() {
    navigate({to: 'WohnanlageEdit', params: {wohnobjekt: this.wohnobjektId, redirectUri: window.location.href}})
  }

  wohnanlageBearbeiten = () => {
    navigate({to: 'WohnanlageEdit', id: this.wohnobjekt.wohnanlage, params: {wohnobjekt: this.wohnobjektId, redirectUri: window.location.href}})
  }

  requestWohungenDelete = wohnungen => {
    const dialog = document.getElementById('wohnungen-loeschen-dialog')
    dialog.description = this.i18n.translate('wohnungen.loeschen.dialog.description', {typ: wohnungen.typ})
    dialog.setAttribute('data-id', wohnungen.id)
    dialog.setAttribute('data-typ', wohnungen.typ)
    dialog.hidden = false
  }

  deleteWohnungen = event => {
    if (event.detail.confirmed) {
      const dialog = document.getElementById('wohnungen-loeschen-dialog')
      const wohnungenService = new WohnungenService(this.wohnobjekt.id)
      wohnungenService.delete(dialog.getAttribute('data-id'))
        .then(_ => {
          showAlert(
            this.i18n.translate('wohnungen.loeschen.success.title'),
            this.i18n.translate('wohnungen.loeschen.success.body', {typ: dialog.getAttribute('data-typ')}),
            'success'
          )
          this.reload()
        })
    }
    event.target.hidden = true
  }


  requestDelete = () => {
    const dialog = document.getElementById('wohnobjekt-loeschen-dialog')
    dialog.description = this.i18n.translate('wohnobjekt.loeschen.dialog.description', {titel: this.wohnobjekt.titel})
    dialog.hidden = false
  }

  delete = event => {
    if (event.detail.confirmed) {
      const wohnobjektService = new WohnobjektService()
      wohnobjektService.delete(this.wohnobjekt.id)
        .then(_ => {
          showAlert(
            this.i18n.translate('wohnobjekt.loeschen.success.title'),
            this.i18n.translate('wohnobjekt.loeschen.success.body', {titel: this.wohnobjekt.titel}),
            'success'
          )
          navigate({to: 'Wohnobjekt'})
        })
    }
    event.target.hidden = true
  }

  getBeschreibung = () => {
    if (isEmpty(this.wohnobjekt.beschreibung)) {
      return ''
    } else {
      return this.wohnobjekt.beschreibung.split('\n').map(line => html`${line}<br>`)
    }
  }

  getMobileViewCss() {
    return css`
      .container {
        margin: calc(var(--isceco-space-300) * -1); /*negate padding on card*/
        border: 2px solid var(--isceco-color-grey-100);
        border-radius: var(--isceco-space-100);
      }

      .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--isceco-space-200);
      }

      .body > .right {
        font-weight: bold;
        text-align: right;
      }

      .body .punkte > span {
        float: left;
        width: 30px;
        text-align: left;
      }

      .header {
        display: flex;
        align-items: center;
        background-color: var(--isceco-color-grey-100);
        padding: var(--isceco-space-200);
      }

      .header > b {
        flex: 1;
      }
    `
  }

  getMobileView(name) {
    return (instance, row) => {
      const hasQuantitaetsPunkte = ['wohnanlage', 'wohnungen']

      const rowFormatted = {}
      instance.columns
        .forEach(column => {
          rowFormatted[column.key] = column.renderCell(this.i18n, row[column.key], row)
        })

      const erstenPunkteAnzeige = hasQuantitaetsPunkte.includes(name) ?
        html`<span>${rowFormatted.quantitaet}</span> ${this.i18n.translate(WohnobjektDetailColumns.QUANTITAET().text)}` :
        html`<span>${rowFormatted.potenzial}</span> ${this.i18n.translate(WohnobjektDetailColumns.POTENZIAL().text)}`

      const editColumns = instance.columns.filter(c => c.text === WohnobjektDetailColumns.EDIT().text)
      const editAnzeige = editColumns.length > 0 ?
        html` <span class="edit">${editColumns[0].renderCell(this.i18n, {}, row)}</span>` :
        html``

      return html`
        <div class="container">
          <div class="header">
            <b>${rowFormatted.code}</b>
            ${editAnzeige}
          </div>
          <div class="body">
            <div class="left">
              <p class="punkte">${erstenPunkteAnzeige}</p>
              <p class="punkte">
                <span>${rowFormatted.qualitaet}</span>
                ${this.i18n.translate(WohnobjektDetailColumns.QUALITAET().text)}
              </p>
              <p class="punkte">
                <span>${rowFormatted.innovation}</span>
                ${this.i18n.translate(WohnobjektDetailColumns.INNOVATION().text)}
              </p>
            </div>
            <div class="right">
              <p class="punkte punkte-total">
                <span>${rowFormatted.total}</span>
                ${this.i18n.translate(WohnobjektDetailColumns.TOTAL().text)}
              </p>
            </div>
          </div>
        </div>
      `
    }
  }

  getColors = () => {
    const style = getComputedStyle(document.body)
    return {
      wohnstandort: style.getPropertyValue('--color-wohnstandort'),
      wohnanlage: style.getPropertyValue('--color-wohnanlage'),
      wohnung: style.getPropertyValue('--color-wohnung'),
      wohnstandort_light: style.getPropertyValue('--color-wohnstandort-light'),
      wohnanlage_light: style.getPropertyValue('--color-wohnanlage-light'),
      wohnung_light: style.getPropertyValue('--color-wohnung-light')
    }
  }

  showTooltip = detail => {
    const tooltip = this.querySelector('#gebrauchswert-tooltip')
    if (detail.show) {
      let color
      if (detail.kriterium.code <= 6) {
        color = this.colors.wohnstandort
      } else if (detail.kriterium.code <= 14) {
        color = this.colors.wohnanlage
      } else {
        color = this.colors.wohnung
      }
      const text = detail.kriterium.innovation > 0 ?
        this.i18n.translate('tooltip.points.innovation', detail.kriterium) :
        this.i18n.translate('tooltip.points', detail.kriterium)

      tooltip.show(
        color,
        this.i18n.translate(`kriterium.${detail.kriterium.code}`),
        text,
        detail.element
      )
    } else {
      tooltip.hide()
    }
  }

  downloadPDF = _ => {
    const exportService = new ExportService()
    exportService.read('pdf', this.wohnobjektId)
      .then(response => response.blob())
      .then(blob => download(toValidFilename(this.wohnobjekt.titel, 'pdf'), blob))
  }

  downloadSVG = _ => {
    const plot = this.querySelector('#spider-plot')
    download(toValidFilename(this.wohnobjekt.titel, 'svg'), plot.getSVG())
  }

  isGesuch = () => !isEmpty(this.wohnobjekt.gesuchStatus)
}
customElements.define('tool-frontend-wohnobjekt-detail', WohnobjektDetail)

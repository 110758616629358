import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'

export default class AktionenCell {
  constructor(configuration) {
    this.buttons = configuration.buttons
  }

  render(i18n, cell, row) {
    const buttons = []
    for (const button of this.buttons) {
      const {callback, text} = button
      const icon = isEmpty(button.icon) ? '' : button.icon
      const disabled = button.disabled !== undefined ? button.disabled : () => false;
      buttons.push(html`
        <isceco-button text="${i18n.translate(text)}"
                       size="small"
                       style="margin: var(--isceco-space-100)"
                       icon="${icon}"
                       ?disabled="${disabled(row)}"
                       @click="${e => {
                         e.preventDefault()
                         e.stopPropagation()
                         return callback(row)
                       }}"
        </isceco-button>
      `)
    }
    return html`
      <div style="display: flex; flex-direction: row; flex-wrap: wrap">
        ${buttons}
      </div>
    `
  }
}

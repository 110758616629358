import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import AktionenCell from '../AktionenCell.js'
import EmailCell from './EmailCell.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js';

export default class GesucheColumns {

  static getColumns(editCallback, assignCallback, disabledCallback) {
    return [
      GesucheColumns.NUMMER(),
      GesucheColumns.EINGEREICHT(),
      GesucheColumns.BEARBEITER(),
      GesucheColumns.TITEL(),
      GesucheColumns.PLZ(),
      GesucheColumns.STANDORT(),
      GesucheColumns.SNBS_FREIGABE(),
      GesucheColumns.SNBS_WEITERLEITEN(),
      GesucheColumns.NAME(),
      GesucheColumns.EMAIL(),
      GesucheColumns.AKTIONEN(editCallback, assignCallback, disabledCallback)
    ]
  }

  static NUMMER() {
    return new StringColumn({
      key: 'nummer',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.nummer'
    })
  }

  static EINGEREICHT() {
    return new GenericColumn({
      key: 'eingereicht',
      sortable: true,
      weight: 2,
      cell: new DateTimeCell(),
      text: 'gesuch.table.header.eingereicht'
    })
  }

  static BEARBEITER() {
    return new GenericColumn({
      cell: new EmailCell(),
      key: 'bearbeiter',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.bearbeiter'
    })
  }

  static TITEL() {
    return new StringColumn({
      key: 'titel',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.titel'
    })
  }

  static PLZ() {
    return new StringColumn({
      key: 'plz',
      sortable: true,
      weight: 1,
      text: 'gesuch.table.header.plz'
    })
  }

  static STANDORT() {
    return new StringColumn({
      key: 'standort',
      sortable: true,
      weight: 1,
      text: 'gesuch.table.header.standort'
    })
  }

  static SNBS_FREIGABE() {
    return new CheckboxColumn({
      key: 'snbsFreigabe',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.snbsFreigabe',
      disabled: _ => true
    })
  }

  static SNBS_WEITERLEITEN() {
    return new CheckboxColumn({
      key: 'snbsWeiterleiten',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.snbsWeiterleiten',
      disabled: _ => true
    })
  }

  static NAME() {
    return new StringColumn({
      key: 'name',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.name'
    })
  }

  static EMAIL() {
    return new GenericColumn({
      cell: new EmailCell(),
      key: 'email',
      sortable: true,
      weight: 2,
      text: 'gesuch.table.header.email'
    })
  }

  static AKTIONEN(editCallback, assignCallback, disabledCallback) {
    return new GenericColumn({
      cell: new AktionenCell({
        buttons: [
          {
            text: 'table.aktionen.bearbeiten',
            icon: 'pencil',
            callback: editCallback
          },
          {
            text: 'table.aktionen.zuweisen',
            icon: 'check user',
            callback: assignCallback,
            disabled: disabledCallback
          }
        ]
      }),
      sortable: false,
      weight: 2,
      text: 'gesuch.table.header.aktionen'
    })
  }

}

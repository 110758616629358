import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import MainWebComponent from '../../MainWebComponent.js'
import VorbereitungCss from './VorbereitungCss.js'

export default class Vorbereitung extends MainWebComponent {

  get translationFile() {
    return './views/Vorbereitung/i18n.json'
  }

  get css() {
    return VorbereitungCss
  }

  connectedCallback() {
    this.currentPage = 1
    super.connectedCallback()
    this.reload()
  }

  reload() {
    this.render()
  }

  getTemplate() {
    return html`
      <div class="close">
        <isceco-button id="close-button"
                       variant="tertiary"
                       icon="close"
                       text="${this.i18n.translate('vorbereitung.close')}"
                       @click="${() => this.close()}"
        ></isceco-button>
      </div>

      <div class="navigation">
        <i class="icon angle left"
           id="page-select-previous"
           @click="${() => this._previousPage()}"
           ?hidden="${this.currentPage === 1}"></i>
        <div class="circle ${this._getActive(1)}" id="page-select-1"></div>
        <div class="circle ${this._getActive(2)}" id="page-select-2"></div>
        <div class="circle ${this._getActive(3)}" id="page-select-3"></div>
        <div class="circle ${this._getActive(4)}" id="page-select-4"></div>
        <div class="circle ${this._getActive(5)}" id="page-select-5"></div>
        <i class="icon angle right"
           id="page-select-next"
           @click="${() => this._nextPage()}"
           ?hidden="${this.currentPage === 5}"></i>
      </div>

      <div class="carousel">
        <div class="page ${this._getActive(1)}" id="page-1">
          <isceco-title text="${this.i18n.translate('methodik.header')}"></isceco-title>
          <div class="content">
            <p>${this.i18n.translate('methodik.stufen')}</p>
            <ol>
              <li>${this.i18n.translate('methodik.stufe.1')}</li>
              <li>${this.i18n.translate('methodik.stufe.2')}</li>
              <li>${this.i18n.translate('methodik.stufe.3')}</li>
            </ol>
            <p>${this.i18n.translate('methodik.punkte')}</p>
          </div>
        </div>

        <div class="page ${this._getActive(2)}" id="page-2">
          <isceco-title text="${this.i18n.translate('unterlagen.header')}"></isceco-title>
          <div class="content">
            ${this.i18n.translate('unterlagen.beschreibung')}
            <ol>
              <li>
                ${this.i18n.translate('unterlagen.wohnstandort')}
                <ul>
                  <li>${this.i18n.translate('unterlagen.wohnstandort.1')}</li>
                  <li>${this.i18n.translate('unterlagen.wohnstandort.2')}</li>
                  <li>${this.i18n.translate('unterlagen.wohnstandort.3')}</li>
                  <li>${this.i18n.translate('unterlagen.wohnstandort.4')}</li>
                </ul>
              </li>
              <li>
                ${this.i18n.translate('unterlagen.wohnanlage')}
                <ul>
                  <li>${this.i18n.translate('unterlagen.wohnanlage.1')}</li>
                  <li>${this.i18n.translate('unterlagen.wohnanlage.2')}</li>
                  <li>${this.i18n.translate('unterlagen.wohnanlage.3')}</li>
                </ul>
              </li>
              <li>
                ${this.i18n.translate('unterlagen.innovation')}
                <ul>
                  <li>${this.i18n.translate('unterlagen.innovation.4')}</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>

        <div class="page ${this._getActive(3)}" id="page-3">
          <isceco-title text="${this.i18n.translate('grundrisse.header')}"></isceco-title>
          <div class="content">
            <p>
              ${this.i18n.translate('grundrisse.zimmer')}
              <isceco-link text="${this.i18n.translate('grundrisse.link')}"
                           url="${this.i18n.translate('grundrisse.link')}"></isceco-link>.
            </p>
            <p>${this.i18n.translate('grundrisse.wohnungstyp')}</p>
            <ul>
              <li>${this.i18n.translate('grundrisse.wohnungstyp.angaben.1')}</li>
              <li>${this.i18n.translate('grundrisse.wohnungstyp.angaben.2')}</li>
              <li>${this.i18n.translate('grundrisse.wohnungstyp.angaben.3')}</li>
            </ul>
          </div>
        </div>

        <div class="page ${this._getActive(4)}" id="page-4">
          <isceco-title text="${this.i18n.translate('innovation.potenzial.header')}"></isceco-title>
          <div class="content">
            <p><b>${this.i18n.translate('potenzial.prefix')}</b> ${this.i18n.translate('potenzial.info')}</p>
            <p><b>${this.i18n.translate('innovation.prefix')}</b> ${this.i18n.translate('innovation.info')}</p>
          </div>
        </div>

        <div class="page ${this._getActive(5)}" id="page-5">
          <isceco-title text="${this.i18n.translate('automatisierung.header')}"></isceco-title>
          <div class="content">
            <p>${this.i18n.translate('automatisierung.berechnung')}</p>
            <ol>
              <li>${this.i18n.translate('automatisierung.vorgehen.1')}</li>
              <li>${this.i18n.translate('automatisierung.vorgehen.2')}</li>
              <li>${this.i18n.translate('automatisierung.vorgehen.3')}</li>
            </ol>
            <p>${this.i18n.translate('automatisierung.automatisiert')}</p>
            <p><b>${this.i18n.translate('automatisierung.bemerkung')}</b></p>
          </div>
        </div>
      </div>
    `
  }

  close = () => {
    navigate({to: 'WohnobjektEdit'})
  }

  _getActive = page => this.currentPage === page ? 'active' : ''

  _previousPage = () => {
    this._updatePage(this.currentPage - 1)
  }

  _nextPage = () => {
    this._updatePage(this.currentPage + 1)
  }

  _updatePage = newPage => {
    if (newPage === 5) {
      this.querySelector('#close-button').variant = 'primary'
    }
    if (newPage >= 1 && newPage <= 5) {
      this.currentPage = newPage
      this.render()
    }
  }
}
customElements.define('tool-frontend-vorbereitung', Vorbereitung)

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import WohnungenService from './WohnungenService.js'
import MainWebComponent from '../../MainWebComponent.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import Freigabestatus from '../Gesuche/Freigabestatus.js'

export default class WohnungenEdit extends MainWebComponent {

  get translationFile() {
    return './views/WohnungenEdit/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjektId = getNavigationParams().get('wohnobjekt')
    this.redirectUri = getNavigationParams().get('redirectUri')
    this.wohnungen = {}
    this.bettmodule = []
    const currentId = getNavigationId()
    if (currentId === undefined) {
      this.isEdit = false
      this.anzahlZimmer = 0
      this.isReadonly = false
      this.reload()
    } else {
      this.isEdit = true
      const wohnungenService = new WohnungenService(this.wohnobjektId)
      const wohnobjektService = new WohnobjektService()
      Promise.all([wohnungenService.read(currentId), wohnobjektService.read(this.wohnobjektId)])
        .then(([wohnung, wohnobjekt]) => {
          this.wohnungen = wohnung
          this.anzahlZimmer = wohnung.anzahlZimmer
          this.bettmodule = wohnung.bettmodule
          this.isReadonly = !isEmpty(wohnobjekt.gesuchStatus) && wohnobjekt.gesuchStatus !== Freigabestatus.KEYS.IN_BEARBEITUNG
          this.reload()
        })
    }
    const self = this
    const bettmoudleRefresh = setInterval(_ => {
      if (this.querySelector("*[name='zimmergroeserals10']")) {
        self.refreshBettmodule()
        self.reload()
        clearInterval(bettmoudleRefresh)
      }
    }, 100)
  }

  reload() {
    this.render()
  }

  getTemplate() {
    const title = this.i18n.translate(`wohnungen.${this.isEdit ? 'edit' : 'create'}.header`)
    const submitCallback = this.isEdit ? this.update : this.upload
    const dropdownAnzahlPlaceholder = this.i18n.translate('wohnungen.form.placeholder.dropdown.anzahl')
    const numberMax2DecimalPlaceholder = this.i18n.translate('wohnungen.form.placeholder.decimal.max.2')
    const numberIntegralPlaceholder = this.i18n.translate('wohnungen.form.placeholder.integral')
    const cancelText = this.isReadonly ?
      this.i18n.translate('wohnungen.form.zurueck') :
      this.i18n.translate('wohnungen.form.abbrechen')
    const submitText = this.isReadonly ? '' : this.i18n.translate('wohnungen.form.speichern')
    const zimmerGroesserAlsHelpText = this.i18n.translate('wohnungen.info.zimmergroeserals')
    const sanitaerAlsHelpText = this.i18n.translate('wohnungen.info.sanitaer')
    return html`
      <isceco-title text="${title}"></isceco-title>
      <isceco-form id="wohnungen-form"
                   submit-button-text="${submitText}"
                   @submit="${e => submitCallback(e.detail)}">
        <div slot="form-elements" class="form two-column-form">
          <div class="two-column-form-group">
            <isceco-text-input required
                               value="${this.wohnungen.typ}"
                               name="typ"
                               placeholder="${this.i18n.translate('wohnungen.form.placeholder.text')}"
                               label="${this.i18n.translate('wohnungen.form.typ')} *"
                               ?disabled="${this.isReadonly}"
            ></isceco-text-input>
            <isceco-dropdown required
                             placeholder="${dropdownAnzahlPlaceholder}"
                             label="${this.i18n.translate('wohnungen.form.anzahlZimmer')} *"
                             .value="${this.wohnungen.anzahlZimmer}"
                             name="anzahlZimmer"
                             .items="${this.getDropdownNumbers(1, 7)}"
                             @change="${e => this.updateZimmer(e)}"
                             ?disabled="${this.isReadonly}"
                             help-text="${this.i18n.translate('wohnungen.info.anzahlZimmer')}"
            ></isceco-dropdown>
            <isceco-number-input required
                                 min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.anzahl}"
                                 name="anzahl"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.anzahl')} *"
                                 ?disabled="${this.isReadonly}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 value="${this.wohnungen.nettoWohnflaeche}"
                                 name="nettoWohnflaeche"
                                 placeholder="${numberMax2DecimalPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.nettoWohnflaeche')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.nettoWohnflaeche')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 max="7"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.zimmergroeserals10}"
                                 name="zimmergroeserals10"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.zimmergroeserals10')}"
                                 @change="${_ => {
                                   this.refreshBettmodule()
                                 }}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${zimmerGroesserAlsHelpText}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 max="7"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.zimmergroeserals12}"
                                 name="zimmergroeserals12"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.zimmergroeserals12')}"
                                 @change="${_ => {
                                   this.refreshBettmodule()
                                 }}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${zimmerGroesserAlsHelpText}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 max="7"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.zimmergroeserals14}"
                                 name="zimmergroeserals14"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.zimmergroeserals14')}"
                                 @change="${_ => {
                                   this.refreshBettmodule()
                                 }}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${zimmerGroesserAlsHelpText}"
            ></isceco-number-input>
            <p id="zimmer-validation" style="color: var(--isceco-color-red-500);display: none;">
              ${this.i18n.translate('wohnungen.form.zimmer.validierung.max')}
            </p>
          </div>
          <div class="two-column-form-group">
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.flaechenmodul}"
                                 name="flaechenmodul"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.flaechenmodul')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.flaechenmodul')}"
            ></isceco-number-input>
            ${this.getBettmodule()}
            <isceco-checkbox class="${this.anzahlZimmer > 2 ? 'hidden' : ''}"
                             label="${this.i18n.translate('wohnungen.form.kochbereichSeparat')}"
                             name="kochbereichSeparat"
                             ?value="${this.wohnungen.kochbereichSeparat}"
                             ?disabled="${this.isReadonly}"
                             help-text="${this.i18n.translate('wohnungen.info.kochbereichSeparat')}"
            ></isceco-checkbox>
          </div>
          <div class="two-column-form-group">
            <isceco-title size="medium" text="${this.i18n.translate('wohnungen.titel.sanitaer')}"></isceco-title>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.sanitaer}"
                                 name="sanitaer"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.sanitaer')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${sanitaerAlsHelpText}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.sanitaerDusche}"
                                 name="sanitaerDusche"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.sanitaerDusche')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${sanitaerAlsHelpText}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.sanitaerBad}"
                                 name="sanitaerBad"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.sanitaerBad')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${sanitaerAlsHelpText}"
            ></isceco-number-input>
          </div>
          <div class="two-column-form-group">
            <isceco-title size="medium" text="${this.i18n.translate('wohnungen.titel.sonstiges')}"></isceco-title>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.schrankmodul}"
                                 name="schrankmodul"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.schrankmodul')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.schrankmodul')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="0"
                                 value="${this.wohnungen.waende}"
                                 name="waende"
                                 placeholder="${numberIntegralPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.waende')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.waende')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 value="${this.wohnungen.aussenbereich}"
                                 name="aussenbereich"
                                 placeholder="${numberMax2DecimalPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.aussenbereich')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.aussenbereich')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 value="${this.wohnungen.abstellbereich}"
                                 name="abstellbereich"
                                 placeholder="${numberMax2DecimalPlaceholder}"
                                 label="${this.i18n.translate('wohnungen.form.abstellbereich')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnungen.info.abstellbereich')}"
            ></isceco-number-input>
          </div>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${cancelText}"
                         @click="${_ => this.cancel()}"></isceco-button>
        </div>
      </isceco-form>
      ${this.getAbbrechenDialog()}
    `
  }

  getAbbrechenDialog() {
    return html`
      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.back(e)}"
      >
      </isceco-dialog>`
  }

  refreshBettmodule() {
    const zimmerAnzahlGroesser12 = this.parseZimmerAnzahl('zimmergroeserals12') + this.parseZimmerAnzahl('zimmergroeserals14')
    const zimmerAnzahlKleiner12 = this.parseZimmerAnzahl('zimmergroeserals10')
    const currentBettmoduleGroesser12 = this.ensureBettmodule(
      this.bettmodule.filter(bettmodul => bettmodul.groesser12),
      zimmerAnzahlGroesser12,
      true
    )
    const currentBettmoduleKLeiner12 = this.ensureBettmodule(
      this.bettmodule.filter(bettmodul => !bettmodul.groesser12),
      zimmerAnzahlKleiner12,
      false
    )
    if (currentBettmoduleKLeiner12.length + currentBettmoduleGroesser12.length > 7) {
      this.querySelector('#zimmer-validation').style.display = 'block'
    } else {
      this.querySelector('#zimmer-validation').style.display = 'none'
      this.bettmodule = [...currentBettmoduleKLeiner12, ...currentBettmoduleGroesser12]
      this.reload()
    }
  }

  ensureBettmodule(currentBettmodule, expectedCount, groesser12) {
    let difference
    if (currentBettmodule.length < expectedCount) {
      difference = expectedCount - currentBettmodule.length
      for (let i = 1; i <= difference; i++) {
        currentBettmodule.push({groesser12: groesser12, anzahl: null})
      }
    } else {
      difference = currentBettmodule.length - expectedCount
      currentBettmodule.splice(currentBettmodule.length - difference, currentBettmodule.length)
    }
    return currentBettmodule
  }

  getBettmodule() {
    let pos = 0
    return html`${this.bettmodule.map(b => {
      ++pos
      return this.getBettmoduleDropdown(b, pos)
    })}`
  }

  getBettmoduleDropdown(bettmodul, pos) {
    const dropdownAnzahlPlaceholder = this.i18n.translate('wohnungen.form.placeholder.dropdown.anzahl')
    if (bettmodul.groesser12) {
      return html`
        <isceco-dropdown label="${pos}. ${this.i18n.translate('wohnungen.form.bettmodulgroesser12')}"
                         .value="${bettmodul.anzahl}"
                         name="bettmodulgroesser12"
                         placeholder="${dropdownAnzahlPlaceholder}"
                         .items="${this.getDropdownNumbers(2, 6)}"
                         @change="${e => {
                           bettmodul.anzahl = e.target.getValue()
                         }}"
                         ?disabled="${this.isReadonly}"
                         help-text="${this.i18n.translate('wohnungen.info.bettmodul')}"
        ></isceco-dropdown>`
    }
    return html`
      <isceco-dropdown label="${pos}. ${this.i18n.translate('wohnungen.form.bettmodulkleiner12')}"
                       .value="${bettmodul.anzahl}"
                       name="bettmodulkleiner12"
                       placeholder="${dropdownAnzahlPlaceholder}"
                       .items="${this.getDropdownNumbers(1, 4)}"
                       @change="${e => {
                         bettmodul.anzahl = e.target.getValue()
                       }}"
                       ?disabled="${this.isReadonly}"
                       help-text="${this.i18n.translate('wohnungen.info.bettmodul')}"
      ></isceco-dropdown>`
  }

  parseZimmerAnzahl(name) {
    return parseInt(this.querySelector(`*[name='${name}']`).getValue()) || 0;
  }

  updateZimmer = event => {
    this.anzahlZimmer = event.detail.value
    this.render()
  }

  getDropdownNumbers(min, max) {
    return Array.from({length: max - min + 1}, (_, i) => ({
      'value': i + min,
      'name': i + min
    }))
  }

  cancel = _ => {
    if (formChanged('#wohnungen-form', this.wohnungen)) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.back()
    }
  }

  back = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    this.navigate()
  }

  upload = wohnung => {
    const wohnungenService = new WohnungenService(this.wohnobjektId)
    wohnung.bettmodule = this.bettmodule
    wohnungenService.create(wohnung)
      .then(_ => {
        showAlert(this.i18n.translate('wohnungen.create.success.title'), this.i18n.translate('wohnungen.create.success.body', {titel: wohnung.typ}), 'success')
        this.navigate()
      })
  }

  update = wohnung => {
    wohnung.id = this.wohnungen.id
    wohnung.bettmodule = this.bettmodule
    const wohnungenService = new WohnungenService(this.wohnobjektId)
    wohnungenService.update(wohnung)
      .then(_ => {
        showAlert(this.i18n.translate('wohnungen.update.success.title'), this.i18n.translate('wohnungen.update.success.body', {titel: wohnung.typ}), 'success')
        this.navigate()
      })
  }

  navigate() {
    if (isEmpty(this.redirectUri)) {
      navigate({to: 'WohnobjektDetail', id: this.wohnobjektId})
    } else {
      navigate({to: this.redirectUri})
    }
  }
}
customElements.define('tool-frontend-wohnungen-edit', WohnungenEdit)

import {css} from '@isceco/widget-library2/external/lit'

export default css`
  ul {
    margin: 0;
    list-style-type: none;
  }

  ul > li {
    text-indent: -20px;
  }

  ul > li:before {
    content: "-";
    text-indent: -20px;
    margin-right: 10px;
  }

  .close {
    text-align: right;
  }

  .carousel > .page {
    padding: 0 10%;
    display: none;
  }

  .carousel > .page.active {
    display: block;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: var(--isceco-color-blue-500);
  }

  .navigation > .angle {
    padding: 12px;
    cursor: pointer;
    margin: 0;
  }
  
  .navigation > .angle[hidden] {
    opacity: 0;
    pointer-events: none;
  }

  .navigation > .circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: var(--isceco-color-blue-300);
    margin: 12px;
    transition: all 250ms;
  }

  .navigation > .circle.active {
    background-color: var(--isceco-color-blue-500);
  }
`

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import GesuchService from '../Gesuche/GesuchService.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import MainWebComponent from '../../MainWebComponent.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/RadioGroup/RadioGroup.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import Freigabestatus from '../Gesuche/Freigabestatus.js'

export default class GesuchEntscheidung extends MainWebComponent {

  get translationFile() {
    return './views/GesuchEntscheidung/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjekt = {}
    this.gesuch = {}
    this.reload()
  }

  reload() {
    const wohnobjektId = getNavigationId()
    const wohnobjektService = new WohnobjektService()
    const gesuchService = new GesuchService()
    Promise.all([wohnobjektService.read(wohnobjektId, '', true), gesuchService.read(`letztes?wohnobjektId=${wohnobjektId}`)])
      .then(([wohnobjektResponse, gesuch]) => {
        wohnobjektResponse.json()
          .then(wohnobjekt => {
            this.wohnobjekt = wohnobjekt
            this.gebrauchswert = wohnobjektResponse.headers.get('X-total')
            this.gesuch = gesuch
            this.render()
          })
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('gesuch.header', {titel: this.wohnobjekt.titel})}"></isceco-title>

      <isceco-form id="gesuch-form"
                   submit-button-text="${this.i18n.translate('gesuch.form.speichern')}"
                   @submit="${e => this.submit(e.detail)}">
        <div slot="form-elements" class="form">
          <isceco-radio-group label="${this.i18n.translate('gesuch.form.freigabestatus')}"
                              required
                              name="freigabestatus"
                              .items="${[
                                {value: Freigabestatus.KEYS.BEWILLIGT, name: this.i18n.translate('gesuch.form.bewilligen')},
                                {value: Freigabestatus.KEYS.ABGELEHNT, name: this.i18n.translate('gesuch.form.ablehnen')}
                              ]}"
                              @change="${e => this.showEntscheidung(e.detail.value)}"
          ></isceco-radio-group>
          <isceco-textarea label="${this.i18n.translate('gesuch.form.entscheidung')}"
                           required
                           name="entscheidung"
                           class="hidden"
                           rows="15"
          ></isceco-textarea>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${this.i18n.translate('gesuch.form.abbrechen')}"
                         @click="${_ => this.cancelEntscheidung()}"></isceco-button>
        </div>
      </isceco-form>
      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.goBack(e)}"
      >
      </isceco-dialog>
    `
  }

  showEntscheidung = value => {
    const gebrauchswertObject = {gebrauchswert: this.gebrauchswert ? this.gebrauchswert : 0}
    const text = []
    if (value === Freigabestatus.KEYS.BEWILLIGT) {
      text.push(this.i18n.translate('gesuch.vorschlag.bewilligen.1'))
      text.push(this.i18n.translate('gesuch.vorschlag.bewilligen.2', gebrauchswertObject))
      text.push(this.i18n.translate('gesuch.vorschlag.bewilligen.3', gebrauchswertObject))
    } else {
      text.push(this.i18n.translate('gesuch.vorschlag.ablehnen.1', gebrauchswertObject))
    }
    text.push('')
    text.push(this.i18n.translate('gesuch.vorschlag.footer.1'))
    text.push(this.i18n.translate('gesuch.vorschlag.footer.2'))
    text.push(this.gesuch.name)
    text.push(this.gesuch.email)

    const element = this.querySelector('[name=entscheidung]')
    element.value = text.join('\n')
    element.classList.remove('hidden')
  }

  cancelEntscheidung = _ => {
    if (formChanged('#gesuch-form')) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.goBack()
    }
  }

  goBack = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    navigate({to: 'WohnobjektDetail', id: this.wohnobjekt.id})
  }

  submit = gesuch => {
    gesuch.id = this.gesuch.id

    const entscheidung = this.i18n.translate(`freigabestatus.${gesuch.freigabestatus}`)
    const gesuchService = new GesuchService()
    gesuchService.update(gesuch)
      .then(_ => {
        showAlert(this.i18n.translate('gesuch.submit.success.title'),
          this.i18n.translate('gesuch.submit.success.body', {titel: this.wohnobjekt.titel, entscheidung: entscheidung}),
          'success')
        navigate({to: 'WohnobjektDetail', id: this.wohnobjekt.id})
      })
  }
}
customElements.define('tool-frontend-gesuch-entscheidung', GesuchEntscheidung)

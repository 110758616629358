export default class BerechtigungDTO {
  constructor(json) {
    if (json) {
      this.id = json.id
      this.email = json.email
      this.erstellt = json.erstellt
      this.type = `${json.type}`
      this.wohnobjekt = json.wohnobjekt
      this.extId = json.extId
    } else {
      this.json = {}
    }
  }
}

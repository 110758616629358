import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import Navigation from '../../views/Navigation/Navigation.js'
import WohnobjektService from '../../views/Wohnobjekt/WohnobjektService.js'
import WebComponent from '../../WebComponent.js'

export default class Breadcrumb extends WebComponent {

  get translationFile() {
    return './views/Breadcrumb/i18n.json'
  }

  get css() {
    return css`
      .breadcrumb > * {
        margin-right: var(--isceco-space-300);
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.content = html``
    // hide breadcrumb on these hashes
    this.noBreadcrumbHashes = ['Wohnobjekt', 'Kontakt', 'Gesuche', 'Auswertungen']
    // hashes, where the ID of the wohnobjekt is not a query parameter, but the navigation ID
    this.detailHashes = ['WohnobjektEdit', 'Berechtigung', 'GesuchEinreichung', 'GesuchEntscheidung', 'GesuchVerlauf']
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.generateTemplate)
    this.reload()
  }

  reload() {
    super.reload()
    // wait for translations to load before setting the initial text
    this.translationLoaded.then(() => {
      this.generateTemplate()
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.generateTemplate)
  }

  getTemplate() {
    // html is generated by generateTemplate
    return this.content
  }

  // called on reload
  // do not call reload() from here, or it will result in an infinite loop
  generateTemplate = () => {
    const wohnobjektId = this._getWohnobjektId()
    if (this.noBreadcrumbHashes.includes(getNavigationHash())) {
      // no breadcrumb
      this.content = html``
      this.render()
    } else if (isEmpty(wohnobjektId)) {
      // only show link to homepage
      this.content = html`
        <div class="breadcrumb">
          <isceco-link .text="${this.i18n.translate('wohnobjekt.uebersicht')}"
                       icon="angle right"
                       @click="${_ => navigate({to: 'Wohnobjekt'})}"
          ></isceco-link>
        </div>
      `
      this.render()
    } else {
      // show link to homepage and wohnobjekt
      const wohnobjektService = new WohnobjektService()
      wohnobjektService.read(wohnobjektId)
        .then(json => {
          this.content = html`
            <div class="breadcrumb">
              <isceco-link .text="${this.i18n.translate('wohnobjekt.uebersicht')}"
                           icon="angle right"
                           @click="${_ => navigate({to: 'Wohnobjekt'})}"></isceco-link>
              <isceco-link text="${json.titel}"
                           icon="angle right"
                           @click="${_ => navigate({to: 'WohnobjektDetail', id: wohnobjektId})}"
              ></isceco-link>
            </div>
          `
          this.render()
        })
    }
  }

  // if set, get ID of wohnobjekt from query parameters or navigation ID
  _getWohnobjektId = () => {
    const params = getNavigationParams()
    if (params.has('wohnobjekt')) {
      return params.get('wohnobjekt')
    } else if (this.detailHashes.includes(getNavigationHash()) && !isEmpty(getNavigationId())) {
      return getNavigationId()
    }
    return ''
  }
}

customElements.define('tool-frontend-breadcrumb', Breadcrumb)

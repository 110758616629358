import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/SingleselectFilter.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/RadioGroup/RadioGroup.js'
import GesuchVerlaufService from './GesuchVerlaufService.js'
import MainWebComponent from '../../MainWebComponent.js'
import GesuchDTO from '../Gesuche/GesuchDTO.js';
import GesucheColumns from './GesucheColumns.js';
import FreigabestatusRowVariant from '../Gesuche/FreigabestatusRowVariant.js';
import ExportService from '../WohnobjektDetail/ExportService.js';

export default class GesuchVerlauf extends MainWebComponent {
  get translationFile() {
    return './views/GesuchVerlauf/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjektId = getNavigationId()
    this.reload()
  }

  reload() {
    const gesuchVerlaufService = new GesuchVerlaufService(this.wohnobjektId)
    gesuchVerlaufService.getBackendListUrl()
      .then(url => {
        this.url = url
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title size="medium" text="${this.i18n.translate('gesuchsverlauf.header')}">
      </isceco-title>
      <isceco-list id="gesuch-verlauf-table"
                   url="${this.url}"
                   max="5"
                   sort="eingereicht"
                   order="desc"
                   .i18n="${this.i18n}"
                   .rowVariant="${new FreigabestatusRowVariant()}"
                   .dtoMapper="${this.toGesuch}"
                   .columns="${GesucheColumns.getColumns(this.requestDownloadPdf)}"
      >
      </isceco-list>
    `
  }

  toGesuch = row => new GesuchDTO(row)

  requestDownloadPdf = row => {
    const exportService = new ExportService()
    exportService.read('pdf/bwo', row.id)
      .then(response => response.blob())
      .then(blob => download(toValidFilename(row.titel, 'pdf'), blob))
  }
}
customElements.define('tool-frontend-gesuch-verlauf', GesuchVerlauf)

import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .inline-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .column.left > isceco-title {
    display: block;
    margin-top: var(--isceco-space-350);
  }

  .column.right {
    width: 25%;
    min-width: 300px;
  }

  .column.right > :not(:first-child) {
    margin-top: var(--isceco-space-300);
  }

  .wohnungen > .column.right {
    margin-top: 96px;
  }

  .column.left {
    flex: 1;
    margin-right: var(--isceco-space-400);
  }

  .total {
    margin-top: var(--isceco-space-300);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--isceco-space-200);
    border-radius: var(--isceco-space-100);
  }

  .wohnstandort-total {
    background-color: var(--color-wohnstandort);
  }

  .wohnanlage-total {
    background-color: var(--color-wohnanlage);
  }

  .wohnungen-total {
    background-color: var(--color-wohnung);
  }

  .total-gesamt {
    background-color: var(--isceco-color-grey-300);
  }

  .buttons {
    margin-top: var(--isceco-space-300);
  }

  .description {
    margin: 0;
  }

  @media (max-width: 991.98px) {
    .inline-reverse {
      display: block;
      flex-direction: row-reverse;
    }

    .column.right, .column.left {
      width: 100%;
    }

    .column.right,
    .wohnungen > .column.right {
      margin-top: var(--isceco-space-400);
    }
  }

  .wohnstandort {
    --isceco-color-grey-100: var(--color-wohnstandort);
    --isceco-color-grey-300: var(--color-wohnstandort);
    --isceco-color-blue-100: var(--color-wohnstandort-light);
  }

  .wohnanlage {
    --isceco-color-grey-100: var(--color-wohnanlage);
    --isceco-color-grey-300: var(--color-wohnanlage);
    --isceco-color-blue-100: var(--color-wohnanlage-light);
  }

  .wohnung {
    --isceco-color-grey-100: var(--color-wohnung);
    --isceco-color-grey-300: var(--color-wohnung);
    --isceco-color-blue-100: var(--color-wohnung-light);
  }
`

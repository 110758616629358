import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WohnanlageService from './WohnanlageService.js'
import MainWebComponent from '../../MainWebComponent.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import Freigabestatus from '../Gesuche/Freigabestatus.js'

export default class WohnanlageEdit extends MainWebComponent {

  get translationFile() {
    return './views/WohnanlageEdit/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjektId = getNavigationParams().get('wohnobjekt')
    this.wohnanlage = {}
    this.reload()
  }

  reload() {
    const currentId = getNavigationId()
    this.redirectUri = getNavigationParams().get('redirectUri')
    if (currentId === undefined) {
      this.isEdit = false
      this.isReadonly = false
      this.render()
    } else {
      this.isEdit = true
      const wohnanlageService = new WohnanlageService(this.wohnobjektId)
      const wohnobjektService = new WohnobjektService()
      Promise.all([wohnanlageService.read(currentId), wohnobjektService.read(this.wohnobjektId)])
        .then(([wohnanlage, wohnobjekt]) => {
          this.wohnanlage = wohnanlage
          this.isReadonly = !isEmpty(wohnobjekt.gesuchStatus) && wohnobjekt.gesuchStatus !== Freigabestatus.KEYS.IN_BEARBEITUNG
          this.render()
        })
    }
  }

  getTemplate() {
    const title = this.i18n.translate(`wohnanlage.${this.isEdit ? 'edit' : 'create'}.header`)
    const submitCallback = this.isEdit ? this.update : this.upload
    const placeholder = this.i18n.translate('wohnanlage.form.placeholder')
    const cancelText = this.isReadonly ?
      this.i18n.translate('wohnanlage.form.zurueck') :
      this.i18n.translate('wohnanlage.form.abbrechen')
    const submitText = this.isReadonly ? '' : this.i18n.translate('wohnanlage.form.speichern')

    return html`
      <isceco-title text="${title}"></isceco-title>

      <isceco-form id="wohnanlage-form"
                   submit-button-text="${submitText}"
                   @submit="${e => submitCallback(e.detail)}">
        <div slot="form-elements" class="form two-column-form">
          <div class="two-column-form-group">
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.aussenbereich}"
                                 name="aussenbereich"
                                 label="${this.i18n.translate('wohnanlage.form.aussenbereich')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.aussenbereich')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.hauseingangszone}"
                                 name="hauseingangszone"
                                 label="${this.i18n.translate('wohnanlage.form.hauseingangszone')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.hauseingangszone')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.abstellraeume}"
                                 name="abstellraeume"
                                 label="${this.i18n.translate('wohnanlage.form.abstellraeume')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.abstellraeume')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.mehrzweckraeume}"
                                 name="mehrzweckraeume"
                                 label="${this.i18n.translate('wohnanlage.form.mehrzweckraeume')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.mehrzweckraeume')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.waschraeume}"
                                 name="waschraeume"
                                 label="${this.i18n.translate('wohnanlage.form.waschraeume')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.waschraeume')}"
            ></isceco-number-input>
            <isceco-number-input min="0"
                                 maximum-fraction-digits="2"
                                 placeholder="${placeholder}"
                                 value="${this.wohnanlage.veraenderbareraeume}"
                                 name="veraenderbareraeume"
                                 label="${this.i18n.translate('wohnanlage.form.veraenderbareraeume')}"
                                 ?disabled="${this.isReadonly}"
                                 help-text="${this.i18n.translate('wohnanlage.info.veraenderbareraeume')}"
            ></isceco-number-input>
          </div>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${cancelText}" @click="${_ => this.cancel()}"></isceco-button>
        </div>
      </isceco-form>

      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.back(e)}"
      >
      </isceco-dialog>
    `
  }

  cancel = _ => {
    if (formChanged('#wohnanlage-form', this.wohnanlage)) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.back()
    }
  }

  back = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    this.navigate()
  }

  upload = wohnanlage => {
    const wohnanlageService = new WohnanlageService(this.wohnobjektId)
    wohnanlageService.create(wohnanlage)
      .then(_ => {
        showAlert(this.i18n.translate('wohnanlage.create.success.title'), this.i18n.translate('wohnanlage.create.success.body'), 'success')
        this.navigate()
      })
  }

  update = wohnanlage => {
    wohnanlage.id = this.wohnanlage.id

    const wohnanlageService = new WohnanlageService(this.wohnobjektId)
    wohnanlageService.update(wohnanlage)
      .then(_ => {
        showAlert(this.i18n.translate('wohnanlage.update.success.title'), this.i18n.translate('wohnanlage.update.success.body'), 'success')
        this.navigate()
      })
  }

  navigate() {
    if (isEmpty(this.redirectUri)) {
      navigate({to: 'WohnobjektDetail', id: this.wohnobjektId})
    } else {
      navigate({to: this.redirectUri})
    }
  }
}
customElements.define('tool-frontend-wohnanlage-edit', WohnanlageEdit)

export default class KriteriumListService {
  constructor(data, min, max) {
    this.data = data
      .filter(kriterium => kriterium.code <= max && kriterium.code >= min)
      .sort((a, b) => a.code > b.code ? 1 : -1)
  }

  async list() {
    return Promise.resolve({
      json: this.data
    })
  }
}

import {html, svg, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import FormCss from './SpiderPlotCss.js'

export class SpiderPlot extends LitElement {

  constructor() {
    super()
    // all numbers here are in coordinates local to the SVG, not screen pixels
    this.size = 370 // width and height of the SVG
    this.radius = 150 // radius of the plot
    this.labelDistance = 20 // distance from the edge of the plot to the labels
    this.tickSize = 5 // length of the tick lines

    // constants used for calculations
    this.ticks = 4
    this.segments = 25

    // filled by properties
    this.centerText = ''
    this.kriterien = []
    this.colors = {}
  }

  static get styles() {
    return [FormCss]
  }

  static get properties() {
    return {
      data: {type: Array},
      centerText: {attribute: 'center-text', type: Number},
      colors: {type: Object}
    }
  }

  connectedCallback() {
    super.connectedCallback()
    this.kriterien = Array.from({length: this.segments}, (_, i) => i + 1)
      .map(code => this.getDataByCode(this.data, code))
  }

  render() {
    return html`
      <div id="graph">${this.renderGraph()}</div>
    `
  }

  /* -------------------------------------------------- RENDERING -------------------------------------------------- */

  // for exporting the style needs to be included in the SVG itself
  get style() {
    return {
      center: 'fill: black; font-size: 23px; font-weight: bold;',
      center_y_offset: 8,
      labels: 'font-size: 11px;',
      labels_y_offset: 4,
      segments: 'stroke: white; stroke-width: 1;',
      spokes: 'stroke: black; stroke-width: 1;',
      text: 'font-family: sans-serif; text-anchor: middle;',
      texts: 'fill: black; opacity: 0.8; font-size: 11px;',
      texts_y_offset: 4,
      ticks: 'fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 1, 5;',
      wohnanlage: `fill: ${this.colors.wohnanlage};`,
      wohnstandort: `fill: ${this.colors.wohnstandort};`,
      wohnung: `fill: ${this.colors.wohnung};`,
      innovation: {
        wohnstandort: `fill: ${this.colors.wohnstandort_light};`,
        wohnanlage: `fill: ${this.colors.wohnanlage_light};`,
        wohnung: `fill: ${this.colors.wohnung_light};`
      }
    }
  }

  // render the graph, organized into groups
  // to simplify, for all calculations the center of the graph remains (0, 0),
  // then the entire one gets translated to display correctly
  // version and xmlns get ignored by the browser, but are necessary for exporting
  renderGraph = _ => {
    return svg`
      <svg viewBox="0 0 ${this.size} ${this.size}"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(${this.size / 2}, ${this.size / 2})" style="${this.style.text}">
          <g id="center" transform="translate(0, ${this.style.center_y_offset})"
                         style="${this.style.center}">${this.renderCenter()}</g>
          <g id="segments" style="${this.style.segments}">${this.renderSegments()}</g>
          <g id="ticks" style="${this.style.ticks}">${this.renderTicks()}</g>
          <g id="spokes" style="${this.style.spokes}">${this.renderSpokes()}</g>
          <g id="texts" transform="translate(0, ${this.style.texts_y_offset})"
                        style="${this.style.texts}">${this.renderTexts()}</g>
          <g id="labels" transform="translate(0, ${this.style.labels_y_offset})"
                         style="${this.style.labels}">${this.renderLabels()}</g>
          <g id="interaction">${this.renderInteractions()}</g>
        </g>
      </svg>
    `
  }

  // render the text in the center of the graph
  renderCenter = _ => {
    return svg`
      <text>${this.centerText}</text>
    `
  }

  // render the colored segments
  renderSegments = _ => {
    return this.kriterien
      .filter(k => k.total > 0)
      .map(k => {
        const innovation = k.innovation === 0 ? '' :
          svg`<path style="${this.style.innovation[k.color]}"
                    d="${this.calcSegment(k.total - k.innovation, k.total, k.index)}"></path>`
        return svg`
          <path style="${this.style[k.color]}"
                d="${this.calcSegment(0, k.total - k.innovation, k.index)}"></path>
          ${innovation}
        `
      })
  }

  // render the circular ticks
  renderTicks = _ => {
    return Array.from({length: this.ticks + 1}, (a, i) => i)
      .map(i => svg`<circle r="${this.calcRadius(i)}"></circle>`)
  }

  // render the spokes, short lines around the outside of the graph
  renderSpokes = _ => {
    return this.kriterien.map(k => {
      const a = this.calcAngle(k.index - 0.5)
      return svg`
        <line x1="${this.calcXPos(this.radius, a)}"
              y1="${this.calcYPos(this.radius, a)}"
              x2="${this.calcXPos(this.radius + this.tickSize, a)}"
              y2="${this.calcYPos(this.radius + this.tickSize, a)}"></line>
      `
    })
  }

  // render the labels for the ticks, displayed in K1
  renderTexts = _ => {
    return Array.from({length: this.ticks}, (a, i) => i + 1)
      .map(i => svg`<text y="${-this.calcRadius(i - 0.5)}">${i}</text>`)
  }

  // render the labels for the criteria, around the outside of the graph
  renderLabels = _ => {
    return this.kriterien.map(k => svg`
      <text style="${this.style[k.color]}"
            x="${this.calcLabelXPos(k.index)}"
            y="${this.calcLabelYPos(k.index)}">
        ${translateText(this._translations(), 'kriterium.kuerzel')}${k.code}
      </text>
    `)
  }

  // this part gets stripped in the export
  // contains all elements required for any interactions in the browser
  // the circles are points, to which the tooltips can be attached
  // do not add groups (<g>) to this, or the export will not work correctly
  renderInteractions = _ => {
    const list = this.kriterien.map(k => svg`
        <circle class="transparent" cx="${this.calcLabelXPos(k.index)}"
                                    cy="${this.calcLabelYPos(k.index)}"
                                    r="1"></circle>
        <path class="transparent hover ${k.color}"
              d="${this.calcSegment(0, this.ticks + 1, k.index)}"
              @mouseenter="${e => this.tooltip(k, e, true)}"
              @mouseleave="${e => this.tooltip(k, e, false)}"
        ></path>
      `)
    list.push(svg`
        <defs>
          <mask id="mask">
            <rect x="${-this.size/2}" y="${-this.size/2}" width="${this.size}" height="${this.size}" opacity="0.5"></rect>
            <path id="mask-path" d=""></path>
          </mask>
        </defs>
      `)
    return list
  }

  /* ----------------------------------------------- HELPER METHODS ------------------------------------------------ */

  // send event to show / hide the tooltip
  tooltip = (kriterium, event, show) => {
    const maskPath = this.shadowRoot.querySelector('#mask-path')
    const mainGroup = this.shadowRoot.querySelector('svg > g')
    if (show) {
      maskPath.setAttribute('d',  event.target.getAttribute('d'))
      mainGroup.setAttribute('mask',  'url(#mask)')
    } else {
      maskPath.setAttribute('d', '')
      mainGroup.removeAttribute('mask')
    }
    send('tooltip', {kriterium: kriterium, element: event.target.previousElementSibling, show: show}, this)
  }

  // create the criteria object, simplified for calculations
  // read the criteria if it exists in the input, or create an empty one
  getDataByCode = (data, code) => {
    let color
    if (code <= 6) {
      color = 'wohnstandort'
    } else if (code <= 14) {
      color = 'wohnanlage'
    } else {
      color = 'wohnung'
    }
    const filtered = data.filter(d => d.code === code)
    if (filtered.length === 0) {
      return {index: code - 1, code: code, innovation: 0, total: 0, color: color}
    } else {
      return {index: code - 1, code: code, innovation: filtered[0].innovation, total: filtered[0].total, color: color}
    }
  }

  // calculate the radius from the value of the criteria
  calcRadius = value => (value + 1) * this.radius / (this.ticks + 1)

  // calculate the rotation angle in radians from the index of the criteria
  calcAngle = i => i * 2 * Math.PI / this.segments

  // calculate x and y position from radius and angle in radians
  calcPos = (r, a) => {
    return {x: this.calcXPos(r, a), y: this.calcYPos(r, a)}
  }

  // calculate x position from radius and angle in radians
  // The angle calculations were adjusted so the 0° is straight up.
  calcXPos = (r, a) => r * Math.sin(a)

  // calculate y position from radius and angle in radians
  // The angle calculations were adjusted so the 0° is straight up.
  calcYPos = (r, a) => -r * Math.cos(a)

  // calculate x position of a label from its index
  calcLabelXPos = index => this.calcXPos(this.radius + this.labelDistance, this.calcAngle(index))

  // calculate y position of a label from its index
  calcLabelYPos = index => this.calcYPos(this.radius + this.labelDistance, this.calcAngle(index))

  // calculate the path definition for a segment
  // the inner and outer value define the inner and outer radius
  // the index is used to calculate the rotation
  calcSegment = (innerValue, outerValue, index) => {
    const rInner = this.calcRadius(innerValue) // inner radius
    const rOuter = this.calcRadius(outerValue) // outer radius
    const aStart = this.calcAngle(index - 0.5) // start angle
    const aEnd = this.calcAngle(index + 0.5) // end angle

    // x, y positions of the 4 corners
    const p1 = this.calcPos(rOuter, aStart)
    const p2 = this.calcPos(rOuter, aEnd)
    const p3 = this.calcPos(rInner, aEnd)
    const p4 = this.calcPos(rInner, aStart)

    // path definition. see: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
    // Mx y : move to x, y, set start position for drawing
    // Arx ry x-axis-rotation large-arc-flag sweep-flag x y :
    //      elliptical arc with radius rx, ry to point x, y
    //      clockwise if sweep=1, counter-clockwise if sweep=0
    //      rotation and large-arc are unused here and always 0
    //      helpful: https://svg-art.ru/wp-content/uploads/2014/11/large-arc-flag-ph.png
    // Lx y : line to x, y
    // Z : close the path with a straight line
    return `
      M${p1.x} ${p1.y}
      A${rOuter} ${rOuter} 0 0 1 ${p2.x} ${p2.y}
      L${p3.x} ${p3.y}
      A${rInner} ${rInner} 0 0 0 ${p4.x} ${p4.y}
      Z
    `
  }

  /* --------------------------------------------------- EXPORT ---------------------------------------------------- */

  // prepare and get the SVG
  getSVG = _ => {
    let svgHtml = this.shadowRoot.querySelector('#graph').innerHTML
    svgHtml = svgHtml.replaceAll(/<!--(\?lit\$[0-9]+\$)?-->/g, '') // remove comments
    svgHtml = svgHtml.replaceAll(/\n\s*/g, '') // remove newlines
    svgHtml = svgHtml.replace(/^\s*/, '') // remove leading whitespace
    svgHtml = svgHtml.replace(/\s*$/, '') // remove trailing whitespace
    // remove parts for interaction in browser
    const interactionIndex = svgHtml.indexOf('id="interaction"')
    const startInteraction = svgHtml.lastIndexOf('<g', interactionIndex)
    const endInteraction = svgHtml.indexOf('</g>', interactionIndex) + 4
    // add xml header
    svgHtml = `<?xml version="1.0" encoding="utf-8" standalone="no"?>\n${svgHtml.substring(0, startInteraction)}${svgHtml.substring(endInteraction)}`
    return svgHtml
  }

  _translations() {
    return {
      de: {
        'kriterium.kuerzel': 'K'
      },
      fr: {
        'kriterium.kuerzel': 'C'
      },
      it: {
        'kriterium.kuerzel': 'C'
      },
      en: {
        'kriterium.kuerzel': 'C'
      }
    };
  }

}

customElements.define('tool-frontend-spider-plot', SpiderPlot)

export default class KriteriumDTO {
  constructor(json) {
    if (json) {
      this.code = `K${json.code}`
      this.codeWithoutPrefix = json.code
      this.potenzial = json.potenzial
      this.quantitaet = json.quantitaet
      this.qualitaet = json.qualitaet
      this.innovation = json.innovation
      this.total = json.total
    } else {
      this.json = {}
    }
  }
}

import WebComponent from '../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/List/StaticList.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import StaticRow from '@isceco/widget-library2/basic-elements/List/StaticRow.js'

export default class Auswertungen extends WebComponent {
  get translationFile() {
    return './views/Auswertungen/i18n.json'
  }

  get css() {
    return css`
      .input {
        width: 100%;
        margin-bottom: var(--isceco-space-300);
      }

      .input > :first-child {
        flex: 1;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    const wohnobjektService = new WohnobjektService()
    this.years = []
    this.data = []
    wohnobjektService.list(`years`)
      .then(json => {
        this.years = json.map(v => ({value: v, name: v.toString()}))
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.header')}"></isceco-title>

      <div class="inline input">
        <isceco-dropdown id="year"
                         inline
                         required
                         label="${this.i18n.translate('auswertungen.year')}"
                         .items="${this.years}"
        ></isceco-dropdown>
        <isceco-button id="execute"
                       text="${this.i18n.translate('auswertungen.execute')}"
                       variant="primary"
                       @click="${this.execute}"
        ></isceco-button>
      </div>
      
      <isceco-static-list id="results"
                          .i18n="${this.i18n}"
                          .columns="${this.columns()}"
                          .data="${this.data}"
      ></isceco-static-list>
    `
  }

  execute = () => {
    const yearInput = this.querySelector('#year')
    if (yearInput.validate()) {
      const year = yearInput.getValue()
      const wohnobjektService = new WohnobjektService()
      wohnobjektService.read(`evaluations/${year}`)
        .then(json => {
          this.data = [
            new StaticRow({key: this.i18n.translate('auswertungen.total'), value: json.total}),
            new StaticRow({key: this.i18n.translate('auswertungen.evaluated'), value: json.evaluated}),
          ]
          this.reload()
        })
    }
  }

  columns = () => [
    new StringColumn({
      key: 'key',
      text: 'auswertungen.key'
    }),
    new StringColumn({
      key: 'value',
      text: 'auswertungen.value'
    })
  ]
}

customElements.define('tool-frontend-auswertungen', Auswertungen)

import Freigabestatus from './Freigabestatus.js'

export default class FreigabestatusRowVariant {
  get(row) {
    if (row.freigabestatus === Freigabestatus.KEYS.ABGELEHNT) {
      return 'error'
    } else if (row.freigabestatus === Freigabestatus.KEYS.BEWILLIGT) {
      return 'success'
    } else {
      return ''
    }
  }
}

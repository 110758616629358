import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';

export default class NoContent extends WebComponent {

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <img
          src="https://media.giphy.com/media/5f2mqsGTHpe5a/giphy.gif"
          alt="not found">
      </div>
    `
  }
}
customElements.define('tool-frontend-no-content', NoContent)

import {html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/Message/Message.js';
import WebComponent from '../../WebComponent.js';

export default class Message extends WebComponent {
  constructor() {
    super()
    this.messageAlert = 'isceco-alert-happend'
  }

  connectedCallback() {
    super.connectedCallback()
    this.setTimerListener = e => {
      if (window.alerts.length > 0) {
        setTimeout(_ => {
          window.alerts.shift()
          send(this.messageAlert)
        }, 10000)
      }
    }

    document.addEventListener(this.messageAlert, this.setTimerListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(this.messageAlert, this.setTimerListener)
  }

  getTemplate() {
    return html`
      <isceco-message></isceco-message>
    `
  }
}

customElements.define('tool-frontend-message', Message)

export default class Navigation {

  static get NAVIGATION_HAPPEND() {
    return 'tool-frontend-navigation-happened'
  }

  static get NAVIGATION_HAPPEND_CHECK_LEAVE() {
    return 'tool-frontend-navigation-happened_check_leave'
  }

  constructor() {
    Navigation.setOnHashChangeListener()
  }

  static setOnHashChangeListener() {
    window.onhashchange = _ => window.send(Navigation.NAVIGATION_HAPPEND, getNavigationHash())
  }

  static sendHashChangeEvent() {
    window.send(Navigation.NAVIGATION_HAPPEND, getNavigationHash())
  }
}
const navigation = new Navigation()

import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import NumberCell from '@isceco/widget-library2/basic-elements/List/cell/NumberCell.js'
import ButtonCell from '@isceco/widget-library2/basic-elements/List/cell/ButtonCell.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'

export default class WohnobjektDetailColumns {

  static getWohnstandortColumns(wohnobjektId) {
    return [
      WohnobjektDetailColumns.NAME(),
      WohnobjektDetailColumns.POTENZIAL(),
      WohnobjektDetailColumns.QUALITAET(),
      WohnobjektDetailColumns.INNOVATION(),
      WohnobjektDetailColumns.TOTAL(),
      WohnobjektDetailColumns.EDIT(wohnobjektId)
    ]
  }

  static getWohnanlageColumns(wohnobjektId) {
    return [
      WohnobjektDetailColumns.NAME(),
      WohnobjektDetailColumns.QUANTITAET(),
      WohnobjektDetailColumns.QUALITAET(),
      WohnobjektDetailColumns.INNOVATION(),
      WohnobjektDetailColumns.TOTAL(),
      WohnobjektDetailColumns.EDIT(wohnobjektId)
    ]
  }

  static getWohnungstypColumns(wohnobjektId, wohnungenId) {
    return [
      WohnobjektDetailColumns.NAME(),
      WohnobjektDetailColumns.QUANTITAET(),
      WohnobjektDetailColumns.QUALITAET(),
      WohnobjektDetailColumns.INNOVATION(),
      WohnobjektDetailColumns.TOTAL(),
      WohnobjektDetailColumns.EDIT(wohnobjektId, wohnungenId)
    ]
  }

  static getWohnungstypDurchschnittColumns() {
    return [
      WohnobjektDetailColumns.NAME(),
      WohnobjektDetailColumns.QUANTITAET(),
      WohnobjektDetailColumns.QUALITAET(),
      WohnobjektDetailColumns.INNOVATION(),
      WohnobjektDetailColumns.TOTAL()
    ]
  }

  static get KRITERIEN_OPTIONS() {
    return Array.from({length: 25}, (_, i) => ({
      value: `K${i + 1}`,
      name: `kriterium.${i + 1}`
    }))
  }

  static NAME() {
    return new DropdownMultiselectColumn({
      weight: 4,
      key: 'code',
      text: 'kriterium.table.header.name',
      items: WohnobjektDetailColumns.KRITERIEN_OPTIONS
    })
  }

  static POTENZIAL() {
    return new GenericColumn({
      weight: 2,
      align: 'right',
      cell: new NumberCell({digits: 1}),
      key: 'potenzial',
      text: 'kriterium.table.header.potenzial'
    })
  }

  static QUANTITAET() {
    return new GenericColumn({
      weight: 2,
      align: 'right',
      cell: new NumberCell({digits: 1}),
      key: 'quantitaet',
      text: 'kriterium.table.header.quantitaet'
    })
  }

  static QUALITAET() {
    return new GenericColumn({
      weight: 2,
      align: 'right',
      cell: new NumberCell({digits: 1}),
      key: 'qualitaet',
      text: 'kriterium.table.header.qualitaet'
    })
  }

  static INNOVATION() {
    return new GenericColumn({
      weight: 2,
      align: 'right',
      cell: new NumberCell({digits: 1}),
      key: 'innovation',
      text: 'kriterium.table.header.innovation'
    })
  }

  static TOTAL() {
    return new GenericColumn({
      weight: 2,
      align: 'right',
      cell: new NumberCell({digits: 1}),
      key: 'total',
      text: 'kriterium.table.header.total'
    })
  }

  static EDIT(wohnobjektId, wohnungenId) {
    const params = {}
    if (!isEmpty(wohnobjektId)) {
      params.wohnobjekt = wohnobjektId
    }
    if (!isEmpty(wohnungenId)) {
      params.wohnung = wohnungenId
    }
    return new GenericColumn({
      cell: new ButtonCell({
        icon: 'pencil',
        variant: 'tertiary',
        callback: row => {
          navigate({
            to: '#Kriterium',
            id: row.codeWithoutPrefix,
            params: params
          })
        }
      }),
      weight: 0.5,
      text: 'kriterium.table.header.edit'
    })
  }


}

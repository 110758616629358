export default class Nutzungsgruppe {
  // @alican Object freeze und als const
  static get KEYS() {
    return {
      BWO: 1,
      BAUTRAGERSCHAFT_GEMEINNUTZIG: 2,
      BAUTRAGERSCHAFT_NICHT_GEMEINNUTZIG: 3,
      PLANUNGSBURO: 4,
      OFFENTLICHE_VERWALTUNG: 5,
      FACH_HOCHSCHULE: 6,
      FINANZINSTITUT: 7,
      IMMOBILIENTREUHANDBURO: 8,
      BERATUNGSSTELLE: 9,
      PRIVAT: 10
    }
  }

  static get ITEMS() {
    return Object.values(Nutzungsgruppe.KEYS)
      .map(value => ({
        value: value,
        name: `nutzungsgruppe.${value}`
      }))
  }
}

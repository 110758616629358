export default class GesuchDTO {
  constructor(json) {
    if (json) {
      this.id = json.id
      this.wohnobjektId = json.wohnobjektId
      this.nummer = json.nummer
      this.eingereicht = json.eingereicht
      this.bearbeiter = json.bearbeiter
      this.titel = json.titel
      this.plz = json.plz
      this.standort = json.standort
      this.snbsFreigabe = json.snbsFreigabe
      this.snbsWeiterleiten = json.snbsWeiterleiten
      this.name = json.name
      this.email = json.email
      this.freigabestatus = json.freigabestatus ? json.freigabestatus : 1
      this.json = json
    } else {
      this.json = {}
    }
  }
}

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import WohnobjektColumns from './WohnobjektColumns.js'
import WohnobjektDTO from './WohnobjektDTO.js'
import WohnobjektService from './WohnobjektService'
import ClickableRowVariant from './ClickableRowVariant.js'
import MainWebComponent from '../../MainWebComponent.js'

export default class Wohnobjekt extends MainWebComponent {

  get translationFile() {
    return './views/Wohnobjekt/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.updateWohnobjekteTable = 'update-wohnobjekte-table-event'
    this.url = ''
    this.reload()
  }

  reload() {
    const wohnobjektService = new WohnobjektService()

    wohnobjektService.getBackendListUrl()
      .then(url => {
        this.url = url
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.header')}"></isceco-title>

      <isceco-button variant="primary" text="${this.i18n.translate('wohnobjekt.create')}"
                     @click="${_ => this.createWohnobjekt()}"></isceco-button>

      <isceco-title text="${this.i18n.translate('table.title')}" size="medium"></isceco-title>

      <isceco-filter-group>
        <isceco-string-filter minchars="0" label="${this.i18n.translate('table.header.titel')}" key="titel"></isceco-string-filter>
        <isceco-string-filter minchars="0" label="${this.i18n.translate('table.header.plz')}" key="plz"></isceco-string-filter>
        <isceco-string-filter minchars="0" label="${this.i18n.translate('table.header.standort')}" key="standort"></isceco-string-filter>
      </isceco-filter-group>

      <isceco-list id="wohnobjekte-table"
                   update-event="${this.updateWohnobjekteTable}"
                   url="${this.url}"
                   max="5"
                   sort="erstellt"
                   .i18n="${this.i18n}"
                   .rowurl="${row => `WohnobjektDetail/${row.id}`}"
                   .rowVariant="${new ClickableRowVariant()}"
                   .dtoMapper="${this.toWohnobjekt}"
                   .columns="${WohnobjektColumns.getColumns(this.requestDeleteWohnobjekt, this.openEdit)}"
      ></isceco-list>

      <isceco-dialog hidden
                     id="wohnobjekt-loeschen-dialog"
                     header="${this.i18n.translate('wohnobjekt.loeschen.dialog.header')}"
                     confirm-button="${this.i18n.translate('wohnobjekt.loeschen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('wohnobjekt.loeschen.dialog.button.cancel')}"
                     @submit="${e => this.deleteWohnobjekt(e)}"
      >
      </isceco-dialog>
    `
  }

  createWohnobjekt = () => {
    navigate({to: 'Vorbereitung'})
  }

  toWohnobjekt = row => new WohnobjektDTO(row)

  requestDeleteWohnobjekt = row => {
    this.currentWohnobjekt = row
    const dialog = document.getElementById('wohnobjekt-loeschen-dialog')
    dialog.description = this.i18n.translate('wohnobjekt.loeschen.dialog.description', {titel: this.currentWohnobjekt.titel})
    dialog.hidden = false
  }

  deleteWohnobjekt = event => {
    if (event.detail.confirmed) {
      const wohnobjektService = new WohnobjektService()
      wohnobjektService.delete(this.currentWohnobjekt.id)
        .then(_ => {
          showAlert(
            this.i18n.translate('wohnobjekt.delete.success.title'),
            this.i18n.translate('wohnobjekt.delete.success.body', {titel: this.currentWohnobjekt.titel}),
            'success'
          )
          send(this.updateWohnobjekteTable)
        })
    }
    event.target.hidden = true
  }

  openDetail = event => {
    navigate({to: 'WohnobjektDetail', id: event.detail.row.id})
  }

  openEdit = row => {
    navigate({to: 'WohnobjektEdit', id: row.id})
  }
}
customElements.define('tool-frontend-wohnobjekt', Wohnobjekt)

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import OrtService from './OrtService.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import Nutzungsanliegen from './Nutzungsanliegen.js'
import Nutzungsgruppe from './Nutzungsgruppe.js'
import MainWebComponent from '../../MainWebComponent.js'

export default class WohnobjektEdit extends MainWebComponent {

  get translationFile() {
    return './views/WohnobjektEdit/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.orteAuswahl = []
    this.wohnobjekt = {
      'land': 'CH'
    }
    this.reload()
  }

  reload() {
    const currentId = getNavigationId()
    const wohnobjektService = new WohnobjektService()
    if (currentId === undefined) {
      this.isEdit = false
      this.isReadonly = false
      wohnobjektService.latest()
        .then(json => {
          this.wohnobjekt.nutzungsanliegen = json.nutzungsanliegen
          this.wohnobjekt.nutzungsgruppe = json.nutzungsgruppe
          this.render()
        })
    } else {
      this.isEdit = true
      wohnobjektService.read(currentId)
        .then(wohnobjekt => {
          this.wohnobjekt = wohnobjekt
          this.isReadonly = !isEmpty(wohnobjekt.gesuchStatus)
          this.render()
        })
    }
  }

  getTemplate() {
    const title = this.i18n.translate(`wohnobjekt.${this.isEdit ? 'edit' : 'create'}.header`)
    const submitCallback = this.isEdit ? this.update : this.upload
    const cancelText = this.isReadonly ?
      this.i18n.translate('wohnobjekt.form.zurueck') :
      this.i18n.translate('wohnobjekt.form.abbrechen')
    const submitText = this.isReadonly ? '' : this.i18n.translate('wohnobjekt.form.speichern')

    return html`
      <isceco-title text="${title}"></isceco-title>

      <isceco-form id="wohnobjekt-form"
                   submit-button-text="${submitText}"
                   @submit="${e => submitCallback(e.detail)}">
        <div slot="form-elements" class="form">
          <isceco-dropdown required
                           .value="${this.wohnobjekt.nutzungsanliegen}"
                           .items="${Nutzungsanliegen.ITEMS}"
                           .i18n="${this.i18n}"
                           placeholder="${this.i18n.translate('nutzungsanliegen.empty')}"
                           label="${this.i18n.translate('wohnobjekt.form.nutzungsanliegen')} *"
                           name="nutzungsanliegen"
                           ?disabled="${this.isReadonly}"
          ></isceco-dropdown>
          <isceco-dropdown required
                           .value="${this.wohnobjekt.nutzungsgruppe}"
                           .items="${Nutzungsgruppe.ITEMS}"
                           .i18n="${this.i18n}"
                           placeholder="${this.i18n.translate('nutzungsgruppe.empty')}"
                           label="${this.i18n.translate('wohnobjekt.form.nutzungsgruppe')} *"
                           name="nutzungsgruppe"
                           ?disabled="${this.isReadonly}"
          ></isceco-dropdown>
          <isceco-text-input required
                             value="${this.wohnobjekt.titel}"
                             name="titel"
                             label="${this.i18n.translate('wohnobjekt.form.titel')} *"
                             ?disabled="${this.isReadonly}"
          ></isceco-text-input>
          <isceco-dropdown required
                           label="${this.i18n.translate('wohnobjekt.form.land')} *"
                           name="land"
                           .items="${[
                             {value: 'CH', name: this.i18n.translate('wohnobjekt.land.1')},
                             {value: 'INTL', name: this.i18n.translate('wohnobjekt.land.2')}
                           ]}"
                           value="${this.wohnobjekt.land}"
                           ?disabled="${this.isReadonly}"
          ></isceco-dropdown>
          <isceco-text-input required
                             value="${this.wohnobjekt.plz}"
                             name="plz"
                             label="${this.i18n.translate('wohnobjekt.form.plz')} *"
                             @keyup="${e => this.plzChanged(e)}"
                             ?disabled="${this.isReadonly}"
          ></isceco-text-input>
          <isceco-text-input required
                             style="flex: 1"
                             value="${this.wohnobjekt.standort}"
                             name="standort"
                             label="${this.i18n.translate('wohnobjekt.form.ort')} *"
                             ?disabled="${this.isReadonly}"
          ></isceco-text-input>
          <div>
            ${this.orteAuswahl.map(ort => this.renderOrtAuswahl(ort))}
          </div>
          <isceco-textarea value="${this.wohnobjekt.beschreibung}"
                           name="beschreibung"
                           label="${this.i18n.translate('wohnobjekt.form.beschreibung')}"
                           rows="5"
                           ?disabled="${this.isReadonly}"
          ></isceco-textarea>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${cancelText}" @click="${_ => this.cancel()}"></isceco-button>
        </div>
      </isceco-form>

      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.back(e)}"
      >
      </isceco-dialog>
    `
  }

  renderOrtAuswahl(ort) {
    return html`
      <isceco-link text='${ort}' @click="${_ => this.ortAuswaehlen(ort)}"></isceco-link><br/>
    `
  }

  ortAuswaehlen(ort) {
    this.querySelector(`*[name='standort']`).value = ort
    this.orteAuswahl = []
    this.render()
  }

  cancel = _ => {
    if (formChanged('#wohnobjekt-form', this.wohnobjekt)) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.back()
    }
  }

  back = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    if (this.wohnobjekt.id && localStorage.getItem('hash') === 'WohnobjektDetail') {
      navigate({to: 'WohnobjektDetail', id: this.wohnobjekt.id})
    } else {
      navigate({to: 'Wohnobjekt'})
    }
  }

  upload = wohnobjekt => {
    const wohnobjektService = new WohnobjektService()
    wohnobjektService.create(wohnobjekt)
      .then(json => {
        showAlert(this.i18n.translate('wohnobjekt.create.success.title'), this.i18n.translate('wohnobjekt.create.success.body', {titel: wohnobjekt.titel}), 'success')
        navigate({to: 'WohnobjektDetail', id: json.id})
      })
  }

  update = wohnobjekt => {
    wohnobjekt.id = this.wohnobjekt.id

    const wohnobjektService = new WohnobjektService()
    wohnobjektService.update(wohnobjekt)
      .then(json => {
        showAlert(this.i18n.translate('wohnobjekt.update.success.title'), this.i18n.translate('wohnobjekt.update.success.body', {titel: wohnobjekt.titel}), 'success')
        navigate({to: 'WohnobjektDetail', id: json.id})
      })
  }
  plzChanged = plz => {
    const land = this.querySelector(`*[name='land']`).getValue()
    this.orteAuswahl = []
    if (land !== 'CH' || isNaN(plz.target.value) || plz.target.value.length < 3) {
      return
    }
    const ortService = new OrtService()
    ortService.read(plz.target.value).then(json => {
      if (json.length === 1) {
        this.querySelector(`*[name='standort']`).value = json[0]
      } else if (json.length > 1) {
        this.orteAuswahl = json
      }
    }).finally(_ => {
      this.render()
    })
  }
}
customElements.define('tool-frontend-wohnobjekt-edit', WohnobjektEdit)

import BenutzerService from './views/Berechtigung/BenutzerService.js';

fetch('./config/config.json', {cache: 'reload'})
  .then(res => res.json())
  .then(config => {
    import(config.ssoKeycloakAdapter/* @vite-ignore */)
      .then(() => {
        window.keycloak = new Keycloak({
          url: config.ssoUrl,
          realm: config.ssoRealm,
          clientId: config.ssoClient
        })
        window.keycloak.init({
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then(authenticated => {
          new BenutzerService().update({id: keycloak.tokenParsed.userExtId})

          // send matomo information about the user mail and extid
          if (_paq !== null && typeof (_paq) !== 'undefined') {
            _paq.push(['setUserId', `${window.keycloak.tokenParsed.email}/${window.keycloak.tokenParsed.userExtId}`]);
          }

          document.querySelector('body').innerHTML = `
<main>
  <div id="mainheader">
    <tool-frontend-header></tool-frontend-header>
    <tool-frontend-horizontal-navigation></tool-frontend-horizontal-navigation>
  </div>
  <div id="maincontent">
    <tool-frontend-message></tool-frontend-message>
    <tool-frontend-breadcrumb></tool-frontend-breadcrumb>
    <tool-frontend-main-view></tool-frontend-main-view>
  </div>
  <div id="mainfooter">
    <tool-frontend-footer></tool-frontend-footer>
  </div>
</main>`
        })
        window.keycloak.onTokenExpired = () => {
          keycloak.updateToken(30);
        }
      })
  })

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/SingleselectFilter.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import MainWebComponent from '../../MainWebComponent.js'
import GesuchService from './GesuchService.js'
import GesucheColumns from './GesucheColumns.js'
import GesuchDTO from './GesuchDTO.js'
import FreigabestatusRowVariant from './FreigabestatusRowVariant.js'
import Freigabestatus from './Freigabestatus.js'
import BenutzerService from '../Berechtigung/BenutzerService.js'

export default class Gesuche extends MainWebComponent {

  get translationFile() {
    return './views/Gesuche/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    const params = getNavigationParams()
    if (!params.has('freigabestatus')) {
      params.set('freigabestatus', '1')
    }
    setNavigationParams(params.toString())
    this.updateGesucheTable = 'update-gesuche-table-event'
    this.gesucheUrl = ''
    this.currentGesuch = {}
    this.bearbeiterListe = []
    this.reload()
  }

  reload() {
    const gesuchService = new GesuchService()
    const benutzerService = new BenutzerService()
    Promise.all([gesuchService.getBackendListUrl(), benutzerService.list()])
      .then(([url, bearbeiterList]) => {
        this.gesucheUrl = url
        this.bearbeiterListe = bearbeiterList
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.header')}"></isceco-title>

      <isceco-filter-group>
        <isceco-singleselect-filter key="freigabestatus"
                                    label="${this.i18n.translate('gesuch.table.header.freigabestatus')}"
                                    .items="${Freigabestatus.FILTER_ITEMS}"
                                    .i18n="${this.i18n}"
        ></isceco-singleselect-filter>
      </isceco-filter-group>

      <isceco-list id="gesuche-table"
                   update-event="${this.updateGesucheTable}"
                   url="${this.gesucheUrl}"
                   max="5"
                   sort="nummer"
                   .i18n="${this.i18n}"
                   .rowVariant="${new FreigabestatusRowVariant()}"
                   .dtoMapper="${this.toGesuch}"
                   .columns="${GesucheColumns.getColumns(this.gesuchBearbeiten, this.gesuchZuweisen, this.editGesuchDisabled)}"
      ></isceco-list>

      <isceco-dialog hidden
                     id="gesuch-zuweisen-dialog"
                     header="${this.i18n.translate('gesuch.zuweisen.dialog.header')}"
                     confirm-button="${this.i18n.translate('gesuch.zuweisen.dialog.speichern')}"
                     cancel-button="${this.i18n.translate('gesuch.zuweisen.dialog.abbrechen')}"
                     @submit="${e => this.submitZuweisenDialog(e)}"
      >
        <isceco-form id="gesuch-zuweisen-form"
                     @submit="${e => this.submitZuweisenForm(e.detail)}">
          <div slot="form-elements" class="form" style="height:300px;">
            <isceco-dropdown required
                             .items="${this.bearbeiterListe}"
                             .i18n="${this.i18n}"
                             placeholder="${this.i18n.translate('gesuch.table.header.bearbeiter')}"
                             label="${this.i18n.translate('gesuch.table.header.bearbeiter')} *"
                             name="bearbeiter"
            ></isceco-dropdown>
            <isceco-text-input required
                               label="${this.i18n.translate('gesuch.table.header.nummer')}"
                               name="nummer"
            ></isceco-text-input>
          </div>
        </isceco-form>
      </isceco-dialog>
    `
  }

  toGesuch = row => new GesuchDTO(row)

  editGesuchDisabled = row => row.freigabestatus !== 1

  gesuchBearbeiten = row => {
    navigate({to: 'WohnobjektDetail', id: row.wohnobjektId})
  }

  gesuchZuweisen = row => {
    this.currentGesuch = row
    this.querySelector('#gesuch-zuweisen-form').getFormElements(true).forEach(element => {
      element.value = this.currentGesuch[element.getAttribute('name')]
      element.classList.remove('error')
      element.requestUpdate()
    })
    this.querySelector('#gesuch-zuweisen-dialog').hidden = false
  }

  submitZuweisenDialog = event => {
    if (event.detail.confirmed) {
      this.querySelector('#gesuch-zuweisen-form')._submit()
    } else {
      event.target.hidden = true
    }
  }

  submitZuweisenForm = gesuch => {
    gesuch.id = this.currentGesuch.id
    const gesuchService = new GesuchService()
    gesuchService.update(gesuch).then(() => {
      showAlert(
        this.i18n.translate('gesuch.zuweisen.success.title'),
        this.i18n.translate('gesuch.zuweisen.success.body', {nummer: gesuch.nummer, bearbeiter: gesuch.bearbeiter}),
        'success'
      )
      send(this.updateGesucheTable)
      this.querySelector('#gesuch-zuweisen-dialog').hidden = true
    })
  }
}
customElements.define('tool-frontend-gesuche', Gesuche)

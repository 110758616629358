import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import GesuchService from '../Gesuche/GesuchService.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import MainWebComponent from '../../MainWebComponent.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'

export default class GesuchEinreichung extends MainWebComponent {

  get translationFile() {
    return './views/GesuchEinreichung/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjekt = {}
    this.reload()
  }

  reload() {
    const wohnobjektId = getNavigationId()
    const wohnobjektService = new WohnobjektService()
    wohnobjektService.read(wohnobjektId)
      .then(json => {
        this.wohnobjekt = json
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title
        text="${this.i18n.translate('gesuch.submit.header', {titel: this.wohnobjekt.titel})}"></isceco-title>
      <p>${this.i18n.translate('gesuch.submit.beschreibung')}</p>
      <isceco-form id="gesuch-form">
        <div slot="form-elements" class="form">
          <isceco-switch label="${this.i18n.translate('gesuch.form.snbs.zertifizierung')}"
                         help-text="${this.i18n.translate('gesuch.form.snbs.zertifizierung.info')}"
                         name="snbsFreigabe"
          ></isceco-switch>
          <isceco-switch label="${this.i18n.translate('gesuch.form.snbs.weiterleiten')}"
                         help-text="${this.i18n.translate('gesuch.form.snbs.weiterleiten.info')}"
                         name="snbsWeiterleiten"
          ></isceco-switch>
          <isceco-switch label="${this.i18n.translate('gesuch.form.darlehensgesuch')}"
                         help-text="${this.i18n.translate('gesuch.form.darlehensgesuch.info')}"
                         name="darlehensgesuch"
                         @change="${e => this.darlehensgesuchChanged(e)}"
          ></isceco-switch>
          <isceco-radio-group label="${this.i18n.translate('gesuch.form.korrespondenzsprache')}"
                              required
                              name="korrespondenzsprache"
                              .items="${[
                                {value: 'DE', name: this.i18n.translate('korrespondenzsprache.de')},
                                {value: 'FR', name: this.i18n.translate('korrespondenzsprache.fr')},
                                {value: 'IT', name: this.i18n.translate('korrespondenzsprache.it')},
                                {value: 'EN', name: this.i18n.translate('korrespondenzsprache.en')}
                              ]}"
          ></isceco-radio-group>
        </div>
        <div slot="buttons" class="inline">
          <isceco-button id="submit-gesuch"
                         text="${this.i18n.translate('gesuch.form.speichern')}"
                         @click="${_ => this.submit()}" disabled>
          </isceco-button>
          <isceco-button
            text="${this.i18n.translate('gesuch.form.abbrechen')}"
            @click="${_ => this.cancelEinreichung()}"
          ></isceco-button>
        </div>
      </isceco-form>
      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.goBack(e)}"
      >
      </isceco-dialog>
    `
  }

  darlehensgesuchChanged = e => {
    const gesuchSubmitButton = this.querySelector('#submit-gesuch');
    if (e.detail.value) {
      gesuchSubmitButton.removeAttribute('disabled')
    } else {
      gesuchSubmitButton.setAttribute('disabled', null)
    }
  }

  cancelEinreichung = _ => {
    if (formChanged('#gesuch-form')) {
      document.querySelector('#abbrechen-dialog').hidden = false
    } else {
      this.goBack()
    }
  }

  goBack = event => {
    if (event) {
      event.target.hidden = true
      if (!event.detail.confirmed) {
        return
      }
    }
    navigate({to: 'WohnobjektDetail', id: this.wohnobjekt.id})
  }

  submit = _ => {
    const gesuchForm = this.querySelector('#gesuch-form')
    if (gesuchForm.validate()) {
      const gesuch = gesuchForm.getValues()
      gesuch.wohnobjektOriginal = this.wohnobjekt.id
      const gesuchService = new GesuchService()
      gesuchService.create(gesuch)
        .then(() => {
          showAlert(this.i18n.translate('gesuch.submit.success.title'), this.i18n.translate('gesuch.submit.success.body', {titel: this.wohnobjekt.titel}), 'success')
          navigate({to: 'WohnobjektDetail', id: this.wohnobjekt.id})
        })
    }
  }
}
customElements.define('tool-frontend-gesuch-einreichung', GesuchEinreichung)

import WebComponent from './WebComponent.js'

export default class MainWebComponent extends WebComponent {

  connectedCallback() {
    super.connectedCallback()

    window.onload = _ => {
      localStorage.removeItem('last_pos')
      localStorage.removeItem('last_hash')
      localStorage.removeItem('pos')
      localStorage.removeItem('hash')
    }

    this.scrollListener = _ => {
      this.state_pos = window.scrollY
    }
    window.addEventListener('scroll', this.scrollListener)

    if (!isEmpty(localStorage.getItem('last_pos')) &&
      !isEmpty(localStorage.getItem('last_hash')) &&
      localStorage.getItem('last_hash') === getNavigationHash()
    ) {
      setTimeout(_ => {
        window.scrollTo({
          top: parseFloat(localStorage.getItem('last_pos')),
          behavior: 'smooth'
        })
      }, 500)
    }
    this.state_hash = getNavigationHash()
    this.state_pos = 0
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('scroll', this.reloadListener)
    if (!isEmpty(localStorage.getItem('pos')) && !isEmpty(localStorage.getItem('hash'))) {
      localStorage.setItem('last_pos', localStorage.getItem('pos'))
      localStorage.setItem('last_hash', localStorage.getItem('hash'))
    }
    localStorage.setItem('pos', this.state_pos.toString())
    localStorage.setItem('hash', this.state_hash)
  }
}

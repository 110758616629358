window._paq = window._paq || [];
fetch('./config/config.json')
  .then(res => res.json())
  .then(res => {
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setDocumentTitle', `${location.hostname}/${document.title}`]);
    _paq.push(['enableLinkTracking']);
    (function () {
      const url = res.matomoMetricsUrl;
      const siteId = res.matomoMetricsSiteId;
      _paq.push(['setTrackerUrl', url + 'matomo.php']);
      _paq.push(['setSiteId', siteId]);
      const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.src = url + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
  });

let currentUrl = location.href;
window.addEventListener('hashchange', function () {
  _paq.push(['setReferrerUrl', currentUrl]);
  currentUrl = '/' + window.location.hash.substring(1);
  _paq.push(['setCustomUrl', currentUrl]);
  _paq.push(['setDocumentTitle', document.title]);

  // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
  _paq.push(['deleteCustomVariables', 'page']);
  _paq.push(['trackPageView']);

  // make Matomo aware of newly added content
  const content = document.getElementById('content');
  _paq.push(['MediaAnalytics::scanForMedia', content]);
  _paq.push(['FormAnalytics::scanForForms', content]);
  _paq.push(['trackContentImpressionsWithinNode', content]);
  _paq.push(['enableLinkTracking']);
});

import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js';
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js';
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js';
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js';
import AktionenCell from '../AktionenCell.js'

export default class BerechtigungColumns {
  static getColumns(loeschenCallback, editCallback) {
    return [
      BerechtigungColumns.EMAIL(),
      BerechtigungColumns.EXT_ID(),
      BerechtigungColumns.TYPE(),
      BerechtigungColumns.ERSTELLT(),
      BerechtigungColumns.AKTIONEN(loeschenCallback, editCallback)
    ]
  }

  static ERSTELLT() {
    return new GenericColumn({
      key: 'erstellt',
      text: 'table.header.erstellt',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static EMAIL() {
    return new StringColumn({
      key: 'email',
      text: 'table.header.email',
      sortable: true
    })
  }

  static get TYPE_OPTIONS() {
    return Array.from({length: 2}, (_, i) => ({
      value: `${i + 1}`,
      name: `berechtigung.type.${i + 1}`
    }))
  }


  static TYPE() {
    return new DropdownMultiselectColumn({
      key: 'type',
      text: 'table.header.type',
      items: this.TYPE_OPTIONS,
      sortable: true
    })
  }

  static EXT_ID() {
    return new StringColumn({
      key: 'extId',
      text: 'table.header.extId',
      sortable: true
    })
  }

  static AKTIONEN(loeschenCallback, editCallback) {
    return new GenericColumn({
      cell: new AktionenCell({
        buttons: [
          {
            text: 'table.aktionen.loeschen',
            icon: 'trash alternate',
            callback: loeschenCallback
          },
          {
            text: 'table.aktionen.bearbeiten',
            icon: 'pencil',
            callback: editCallback
          }
        ]
      }),
      sortable: false,
      text: 'table.header.aktionen'
    })
  }
}

import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js';
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js';
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js';
import AktionenCell from '../AktionenCell.js';
import AnzahlGeteiltMitCell from './AnzahlGeteiltMitCell.js';

export default class WohnobjektColumns {

  static getColumns(loeschenCallback, editCallback) {
    return [
      WohnobjektColumns.TITEL(),
      WohnobjektColumns.PLZ(),
      WohnobjektColumns.STANDORT(),
      WohnobjektColumns.ERSTELLT(),
      WohnobjektColumns.ANZAHL_GETEILT_MIT(),
      WohnobjektColumns.AKTIONEN(loeschenCallback, editCallback)
    ]
  }

  static ERSTELLT() {
    return new GenericColumn({
      key: 'erstellt',
      text: 'table.header.erstellt',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static TITEL() {
    return new StringColumn({
      key: 'titel',
      text: 'table.header.titel',
      sortable: true
    })
  }

  static PLZ() {
    return new StringColumn({
      key: 'plz',
      text: 'table.header.plz',
      sortable: true
    })
  }

  static STANDORT() {
    return new StringColumn({
      key: 'standort',
      text: 'table.header.standort',
      sortable: true
    })
  }

  static ANZAHL_GETEILT_MIT() {
    return new GenericColumn({
      cell: new AnzahlGeteiltMitCell(),
      key: 'anzahlGeteiltMit',
      text: 'table.header.anzahlGeteiltMit',
      sortable: false
    })
  }

  static AKTIONEN(loeschenCallback, editCallback) {
    return new GenericColumn({
      cell: new AktionenCell({
        buttons: [
          {
            text: 'table.aktionen.loeschen',
            icon: 'trash alternate',
            callback: loeschenCallback
          },
          {
            text: 'table.aktionen.bearbeiten',
            icon: 'pencil',
            callback: editCallback
          }
        ]
      }),
      sortable: false,
      text: 'table.header.aktionen'
    })
  }
}

import BaseService from '../../BaseService.js';

export default class KriteriumService extends BaseService {

  constructor(wohnobjektId, wohnungId) {
    let url = 'wohnobjekte/' + wohnobjektId;
    if(wohnungId){
      url += '/wohnungen/'+wohnungId;
    }
    url += '/kriterien'
    super(url)
  }

  async list(serviceName) {
    await this.getBackendListUrl(serviceName).then(url => this.serviceurl = url)
    return this.checkError(
      fetch(this.serviceurl, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      }), true
    )
  }
}

import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Link/Link.js'

export default class EmailCell {
  render(i18n, cell, row) {
    if (isEmpty(cell)) {
      return ''
    }
    return html`
      <isceco-link url="mailto:${cell}" text="${cell}"></isceco-link>
    `
  }
}

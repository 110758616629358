import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WohnungenService from '../WohnungenEdit/WohnungenService.js'
import KriteriumService from './KriteriumService.js'
import KriteriumConfig from './KriteriumConfig.js'
import MainWebComponent from '../../MainWebComponent.js'
import WohnobjektService from '../Wohnobjekt/WohnobjektService.js'
import Freigabestatus from '../Gesuche/Freigabestatus.js'
import Navigation from '../Navigation/Navigation.js';

export default class Kriterium extends MainWebComponent {
  get css() {
    return css`
      .kriterium-image {
        width: 100%;
      }

      isceco-checkbox {
        margin: var(--isceco-space-300) 0;
      }

      .faq-item {
        margin: var(--isceco-space-300) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .highlight {
        color: var(--isceco-color-red-500)
      }
    `
  }

  get translationFile() {
    return './views/Kriterium/i18n.json'
  }

  constructor() {
    super()
    const self = this
    const checkQualitaetenInterval = setInterval(() => {
      const form = this.querySelector('.form')
      if (form !== null) {
        self._checkQualitaeten()
        clearInterval(checkQualitaetenInterval)
      }
    }, 100)
  }

  connectedCallback() {
    super.connectedCallback()
    this.wohnobjektId = getNavigationParams().get('wohnobjekt')
    this.wohnungId = getNavigationParams().get('wohnung')
    this.code = getNavigationId()
    this.config = KriteriumConfig.get(this.code)
    this.confirmLeave = event => {
      if (formChanged('#kriterium-form', this.kriterium)) {
        event.preventDefault()
        event.returnValue = ''
        return event.returnValue
      } else {
        return undefined
      }
    }
    this.navigationListener = e => {
      if (formChanged('#kriterium-form', this.kriterium) && !this.submitted) {
        this.leaveConfirmed = null
        document.querySelector('#abbrechen-dialog').hidden = false
        const interval = setInterval(_ => {
          e.preventDefault()
          e.stopPropagation()
          if (this.leaveConfirmed) {
            history.replaceState(null, null, e.detail.newURL);
            Navigation.sendHashChangeEvent()
            Navigation.setOnHashChangeListener()
          } else {
            history.replaceState(null, null, e.detail.oldURL);
          }
          if (this.leaveConfirmed !== null) {
            clearInterval(interval)
          }
        }, 250)
      } else {
        Navigation.sendHashChangeEvent()
        Navigation.setOnHashChangeListener()
      }
    }
    window.addEventListener('beforeunload', this.confirmLeave)
    window.addEventListener(Navigation.NAVIGATION_HAPPEND_CHECK_LEAVE, this.navigationListener)
    window.onhashchange = e => {
      window.send(Navigation.NAVIGATION_HAPPEND_CHECK_LEAVE, e)
    }
    this.reload()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('beforeunload', this.confirmLeave)
    window.removeEventListener(Navigation.NAVIGATION_HAPPEND_CHECK_LEAVE, this.navigationListener)
    Navigation.setOnHashChangeListener()
  }

  reload() {
    const kriteriumService = new KriteriumService(this.wohnobjektId, this.wohnungId)
    const wohnungenService = new WohnungenService(this.wohnobjektId)
    const wohnobjektService = new WohnobjektService()
    Promise.all([kriteriumService.read(this.code), wohnobjektService.read(this.wohnobjektId)])
      .then(([kriterium, wohnobjekt]) => {
        this.kriterium = kriterium
        this.wohnobjekt = wohnobjekt
        this.isGesuch = !isEmpty(wohnobjekt.gesuchStatus)
        this.isReadonly = !isEmpty(wohnobjekt.gesuchStatus) && wohnobjekt.gesuchStatus !== Freigabestatus.KEYS.IN_BEARBEITUNG
        if (!isEmpty(this.wohnungId)) {
          wohnungenService.read(this.wohnungId).then(wohnung => {
            this.wohnungstyp = wohnung.typ
            this.render()
          }).catch(_ => {
            this.render()
          })
        } else {
          this.render()
        }
      }).catch(_ => {
        this.render()
      }
    )
  }

  getTemplate() {
    const headerTitle = `k${this.config.code}.header`
    const headerTitleWohnungstyp = !isEmpty(this.wohnungstyp) ? `: ${this.wohnungstyp}` : ''
    const headerDescription = `k${this.config.code}.description`
    const cancelText = this.isReadonly ?
      this.i18n.translate('kriterium.form.zurueck') :
      this.i18n.translate('kriterium.form.abbrechen')
    const submitText = this.isReadonly ? '' : this.i18n.translate('kriterium.form.speichern')

    return html`
      <isceco-title text="${this.i18n.translate(headerTitle)}${headerTitleWohnungstyp}"></isceco-title>

      ${this._renderNavigationZuEdit()}

      <p>${this.i18n.translate(headerDescription)}</p>

      <isceco-form submit-button-text="${submitText}"
                   @submit="${e => this.update(e.detail)}"
                   id="kriterium-form">
        <div slot="form-elements" class="form">
          ${this._renderPotenzial()}
          ${this._renderQuantitaet()}
          ${this._renderQualitaeten()}
          ${this._renderInnovation()}
          ${this._renderAnmerkung()}
          ${this._renderAnmerkungBWO()}
          ${this._renderFAQ()}
        </div>
        <div slot="buttons" class="inline">
          <isceco-button text="${cancelText}"
                         @click="${_ => this.cancel()}"></isceco-button>
        </div>
      </isceco-form>

      <isceco-dialog hidden
                     id="abbrechen-dialog"
                     header="${this.i18n.translate('abbrechen.dialog.header')}"
                     description="${this.i18n.translate('abbrechen.dialog.description')}"
                     confirm-button="${this.i18n.translate('abbrechen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('abbrechen.dialog.button.cancel')}"
                     @submit="${e => this.back(e)}"
      >
      </isceco-dialog>
    `
  }

  _renderNavigationZuEdit() {
    if (KriteriumConfig.getWohnanlageKriterien().filter(k => k.code === this.config.code).length > 0) {
      return html`
        <isceco-link text="${this.i18n.translate('wohnanlage.bearbeiten')}"
                     icon="pencil"
                     @click="${_ => navigate({
                       to: 'WohnanlageEdit',
                       id: this.wohnobjekt.wohnanlage,
                       params: {wohnobjekt: this.wohnobjektId, redirectUri: window.location.href}
                     })}">
        </isceco-link>
      `
    }
    if (KriteriumConfig.getWohnungenKriterien().filter(k => k.code === this.config.code).length > 0) {
      return html`
        <isceco-link text="${this.i18n.translate('wohnungen.bearbeiten')}"
                     icon="pencil"
                     @click="${_ => navigate({
                       to: 'WohnungenEdit',
                       id: this.wohnungId,
                       params: {wohnobjekt: this.wohnobjektId, redirectUri: window.location.href}
                     })}">
        </isceco-link>
      `
    }
    return html``
  }

  _renderPotenzial() {
    if (this.config.potenzial) {
      const modal = this._renderModal('potenzial', this.config.potenzialFurtherInfoContent)
      const modalInfoButton = this._renderModalInfoButton('potenzial', this.config.potenzialFurtherInfoContent)
      return html`
        <div>
          <isceco-title size="medium" text="${this.i18n.translate('potenzial.header')}">
            ${modalInfoButton}
          </isceco-title>
          <isceco-textarea name="potenzial"
                           value="${this.kriterium.potenzial}"
                           rows="5"
                           ?disabled="${this.isReadonly}"
          ></isceco-textarea>
        </div>
        ${modal}
      `
    }
    return ''
  }

  _renderQuantitaet() {
    if (this.config.quantitaet !== undefined) {
      const modal = this._renderModal('quantitaet', this.config.quantitaetFurtherInfoContent)
      const modalInfoButton = this._renderModalInfoButton('quantitaet', this.config.quantitaetFurtherInfoContent)
      const quantitaetImage = this.config.quantitaetImage ?
        html`
          <div class="modal-image-container"
               onclick="this.classList.toggle('modal-image-larger')">
            <img class="modal-image kriterium-image"
                 src="${this._calcImageSrc(this.kriterium.quantitaet)}"
                 alt="${this.i18n.translate('quantitaet.header.image')}"/>
          </div>
        ` : html``
      const quanitaetEnabled = !this.isReadonly && this.config.quantitaetEditable

      return html`
        <div>
          <isceco-title size="medium" text="${this.i18n.translate('quantitaet.header')}">
            ${modalInfoButton}
          </isceco-title>
          ${quantitaetImage}
          <isceco-dropdown .value="${this.kriterium.quantitaet}"
                           placeholder="${this.i18n.translate('quantitaet.empty')}"
                           name="quantitaet"
                           @change="${() => this._checkQualitaeten()}"
                           .items="${this.config.quantitaet.map(q => ({value: q, name: q}))}"
                           ?disabled="${!quanitaetEnabled}"
          ></isceco-dropdown>
        </div>
        ${modal}
      `
    }
    return ''
  }

  _renderQualitaeten() {
    if (this.config.qualitaeten) {
      const quanitaetenRendered = Array
        .from({length: this.config.qualitaeten}, (_, i) => `k${this.config.code}.qualitaet_${i + 1}`)
        .map((item, index) => this._renderQualitaet(item, `qualitaet_${index + 1}`))
      const checkQualitaetenHelpText = this.config.qualityEditableLogic ?
        html`
          <b>${this.i18n.translate('qualitaeten.checkQualitaeten.help.hinweis')}</b> ${this.i18n.translate('qualitaeten.checkQualitaeten.help.text')}` :
        html``
      const modal = this._renderModal('qualitaeten', this.config.qualitaetenFurtherInfoContent)
      const modalInfoButton = this._renderModalInfoButton('qualitaeten', this.config.qualitaetenFurtherInfoContent)
      return html`
        <div>
          <isceco-title size="medium" text="${this.i18n.translate('qualitaeten.header')}">
            ${modalInfoButton}
          </isceco-title>
          <p id="quality-helptext">
            ${checkQualitaetenHelpText}
          </p>
          ${quanitaetenRendered}
        </div>
        ${modal}
      `
    }
    return ''
  }

  _renderQualitaet(qualitaet, key) {
    const value = this.kriterium[key]
    return html`
      <isceco-checkbox name="${key}"
                       label="${this.i18n.translate(qualitaet)}"
                       ?value="${value}"
                       ?disabled="${this.isReadonly}">
      </isceco-checkbox>
    `
  }

  _renderInnovation() {
    if (this.config.innovation) {
      const modal = this._renderModal('innovation', this.config.innovationFurtherInfoContent)
      const modalInfoButton = this._renderModalInfoButton('innovation', this.config.innovationFurtherInfoContent)
      return html`
        <div>
          <isceco-title size="medium" text="${this.i18n.translate('innovation.header')}">
            ${modalInfoButton}
          </isceco-title>
          <isceco-textarea name="innovation"
                           @change="${() => this._checkQualitaeten()}"
                           value="${this.kriterium.innovation}"
                           rows="5"
                           ?disabled="${this.isReadonly}">
          </isceco-textarea>
        </div>
        ${modal}
      `
    }
    return ''
  }

  _calcImageSrc(quantitaet) {
    return `./images/${getLanguage()}/k${this.config.code}/${quantitaet ? quantitaet : 0}.png`
  }

  _checkQualitaeten() {
    const kriteriumImage = this.querySelector('.kriterium-image')
    const innovation = this.querySelector('*[name=\'innovation\']')
    const quantitaet = this.querySelector('*[name=\'quantitaet\']')
    if(!isEmpty(kriteriumImage)){
      kriteriumImage.setAttribute('src', this._calcImageSrc(quantitaet.getValue()))
    }

    if (this.config.qualityEditableLogic && !isEmpty(innovation) && !isEmpty(quantitaet)) {
      if (isEmpty(innovation.getValue()) && (isEmpty(quantitaet.getValue()) || quantitaet.getValue() <= 0)) {
        this.querySelector('#quality-helptext').classList.add('highlight')
        this.querySelectorAll('*[name*=\'qualitaet_\']').forEach(qualitaet => {
          qualitaet.value = false
          qualitaet.setAttribute('disabled', 'true')
        })
      } else {
        this.querySelector('#quality-helptext').classList.remove('highlight')
        this.querySelectorAll('*[name*=\'qualitaet_\']').forEach(qualitaet => {
          qualitaet.removeAttribute('disabled')
        })
      }
    }
  }

  _renderAnmerkung() {
    return html`
      <div>
        <isceco-title size="medium"
                      text="${this.i18n.translate('anmerkung.header')}"
        ></isceco-title>
        <isceco-textarea name="anmerkung"
                         value="${this.kriterium.anmerkung}"
                         rows="5"
                         ?disabled="${this.isGesuch}"
                         ?disabled="${this.isReadonly}"
        ></isceco-textarea>
      </div>
    `
  }

  _renderAnmerkungBWO() {
    return this.isGesuch ? html`
      <div>
        <isceco-title size="medium"
                      text="${this.i18n.translate('anmerkung.bwo.header')}"
        ></isceco-title>
        <isceco-textarea name="anmerkung_bwo"
                         value="${this.kriterium.anmerkung_bwo}"
                         rows="5"
                         ?disabled="${this.isReadonly}"
        ></isceco-textarea>
      </div>
    ` : html``
  }

  _renderFAQ() {
    const faqRendered = []
    if (this.config.faq) {
      Array.from({length: this.config.faq}, (_, i) => `k${this.config.code}.faq_${i + 1}`)
        .forEach(item => faqRendered.push(this._renderFAQItem(item)))
    }

    if (this.config.faqGrundausstattung) {
      faqRendered.push(this._renderFAQItem('kriterium.grundausstattung.faq', 'grundausstattung'))
    }

    if (this.config.faqSchablone) {
      const modul = this.i18n.translate(`kriterium.schablone.modul.${this.config.code}`)
      faqRendered.push(this._renderFAQItem('kriterium.schablone.faq', modul))
    }

    if (faqRendered.length > 0) {
      return html`
        <div>
          <isceco-title size="medium" text="${this.i18n.translate('faq.header')}"></isceco-title>
          ${faqRendered}
        </div>
        <isceco-dialog id="modal-faq"
                       size="large"
                       hidden
                       @submit="${e => {
                         e.target.hidden = true
                         e.preventDefault()
                         e.stopPropagation()
                       }}"
        ></isceco-dialog>
      `
    } else {
      return html``
    }
  }

  _renderFAQItem(key, identifier) {
    let question = this.i18n.translate(`${key}`)
    if (identifier && identifier !== 'grundausstattung') {
      question = this.i18n.translate(`${key}`, {modul: identifier})
    }
    return html`
      <div class="faq-item">
        <span>${question}</span>
        <isceco-button variant="tertiary"
                       icon="info circle"
                       @click="${() => this._openFAQDialog(key, identifier)}"
        ></isceco-button>
      </div>
    `
  }

  _openFAQDialog(key, identifier = undefined) {
    let innerHTML
    let header = this.i18n.translate(`${key}`)
    if (identifier) {
      if (identifier === 'grundausstattung') {
        const url = this.i18n.translate('kriterium.grundausstattung.faq.url')
        innerHTML = `
          ${this.i18n.translate('kriterium.grundausstattung.faq.antwort')}
          <isceco-link url="${url}" text="${url}" target="_blank"></isceco-link>.
        `
      } else {
        const url = this.i18n.translate('kriterium.schablone.faq.url')
        innerHTML = `
          ${this.i18n.translate('kriterium.schablone.faq.antwort.1')}
          <isceco-link url="${url}" text="${url}" target="_blank"></isceco-link>
          ${this.i18n.translate('kriterium.schablone.faq.antwort.2')}
        `
        header = this.i18n.translate(`${key}`, {modul: identifier})
      }
    } else {
      innerHTML = this.i18n.translate(`${key}.antwort`)
    }

    const dialog = this.querySelector('#modal-faq')
    dialog.header = header
    dialog.innerHTML = innerHTML
    dialog.hidden = false
  }

  _renderModal(name, contentCallback) {
    const modalTitle = `${name}.modal.title`
    return contentCallback !== undefined ? html`
      <isceco-dialog id="modal-${name}"
                     size="large"
                     hidden
                     header="${this.i18n.translate(modalTitle)}"
                     @submit="${e => {
                       e.target.setAttribute('hidden', '')
                       e.target
                         .querySelectorAll('.modal-image-container')
                         .forEach(modalImage => modalImage.classList.remove('modal-image-larger'))
                       e.preventDefault()
                       e.stopPropagation()
                     }}"
      >
        ${contentCallback(this.i18n)}
      </isceco-dialog>
    ` : html``
  }

  _renderModalInfoButton(name, contentCallback) {
    const modalSelector = `#modal-${name}`
    return contentCallback !== undefined ? html`
      <isceco-button variant="tertiary"
                     size="small"
                     icon="info circle"
                     @click="${_ => this.querySelector(modalSelector).removeAttribute('hidden')}">
      </isceco-button>
    ` : html``
  }

  cancel = _ => {
    navigate({to: 'WohnobjektDetail', id: this.wohnobjektId})
  }

  back = event => {
    if (event) {
      event.target.hidden = true
      this.leaveConfirmed = event.detail.confirmed
    }
  }

  update = kriterium => {
    kriterium.id = this.kriterium.code
    const kriteriumService = new KriteriumService(this.wohnobjektId, this.wohnungId)
    kriteriumService.update(kriterium)
      .then(_ => {
        showAlert(this.i18n.translate('kriterium.update.success.title'), this.i18n.translate('kriterium.update.success.body', {code: this.kriterium.code}), 'success')
        this.submitted = true
        if (this.isLastKriteriumOfSection()) {
          navigate({to: 'WohnobjektDetail', id: this.wohnobjektId})
        } else {
          const params = {}
          if (!isEmpty(this.wohnobjektId)) {
            params.wohnobjekt = this.wohnobjektId
          }
          if (!isEmpty(this.wohnungId)) {
            params.wohnung = this.wohnungId
          }
          navigate({
            to: '#Kriterium',
            id: this.config.code + 1,
            params: params
          })
        }
      })
  }

  isLastKriteriumOfSection() {
    return this.containsLastKriterium(KriteriumConfig.getWohnstandortKriterien()) ||
      this.containsLastKriterium(KriteriumConfig.getWohnanlageKriterien()) ||
      this.containsLastKriterium(KriteriumConfig.getWohnungenKriterien())
  }

  containsLastKriterium(kriterien) {
    const filteredKriterien = kriterien.filter(k => k.code === this.config.code)
    return filteredKriterien.length > 0 && filteredKriterien[0].code === kriterien[kriterien.length - 1].code
  }
}

customElements.define('tool-frontend-kriterium', Kriterium)

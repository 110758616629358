export default class Freigabestatus {
  // @alican Object freeze und als const
  static get KEYS() {
    return {
      IN_BEARBEITUNG: 1,
      BEWILLIGT: 2,
      ABGELEHNT: 3
    }
  }

  static get ITEMS() {
    return Object.values(Freigabestatus.KEYS)
      .map(value => ({
        value: value,
        name: `freigabestatus.${value}`
      }))
  }

  static get FILTER_ITEMS() {
    return Freigabestatus.ITEMS
      .map(({value, name}) => ({
        value: value.toString(),
        name: name
      }))
  }
}

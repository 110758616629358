export default class Authorization {
  static get ROLES() {
    return {
      user: 'BWO-wbs.USER',
      bwoMitarbeiter: 'BWO-wbs.MITARBEITER'
    }
  }

  static get RIGHTS() {
    return {
      admin: 1,
      edit: 2
    }
  }

  static get ACTIONS() {
    return {
      wohnobjekt: {
        delete: 'wohnobjekt_delete',
        berechtigungen: 'wohnobjekt_berechtigungen'
      }
    }
  }

}

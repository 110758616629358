import Authorization from './Authorization.js';

export default class AuthorizationFilter {
  constructor() {
    this.roles = {
      user: window.hasRole(Authorization.ROLES.user),
      bwoMitarbeiter: window.hasRole(Authorization.ROLES.bwoMitarbeiter)
    }
  }

  viewsAllowed(names) {
    return names.filter(name => this.viewAllowed(name))
  }

  viewAllowed(name) { // NOSONAR complexity: all options required, doesn't make sense to split
    switch (name) {
      case 'Berechtigung':
      case 'GesuchEinreichung':
      case 'GesuchVerlauf':
      case 'Kontakt':
      case 'Kriterium':
      case 'Vorbereitung':
      case 'WohnanlageEdit':
      case 'Wohnobjekt':
      case 'WohnobjektDetail':
      case 'WohnobjektEdit':
      case 'WohnungenEdit':
        return this.roles.user || this.roles.bwoMitarbeiter
      case 'Gesuche':
      case 'GesuchEntscheidung':
      case 'Auswertungen':
        return this.roles.bwoMitarbeiter
      default:
        return false
    }
  }
}

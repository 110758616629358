import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js';
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js';
import AktionenCell from '../AktionenCell.js';
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js';
import Freigabestatus from '../Gesuche/Freigabestatus.js';
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js';
import EmailCell from '../Gesuche/EmailCell.js';

export default class GesucheColumns {

  static getColumns(pdfDownloadCallback) {
    return [
      GesucheColumns.ZEITSTEMPFEL(),
      GesucheColumns.NAME(),
      GesucheColumns.EMAIL(),
      GesucheColumns.ERGEBNIS(),
      GesucheColumns.AKTIONEN(pdfDownloadCallback)
    ]
  }

  static ZEITSTEMPFEL() {
    return new DateColumn({
      key: 'eingereicht',
      sortable: true,
      text: 'gesuchverlauf.table.header.zeitstempel'
    })
  }

  static NAME() {
    return new StringColumn({
      key: 'name',
      weight: 2,
      text: 'gesuchverlauf.table.header.name'
    })
  }

  static EMAIL() {
    return new GenericColumn({
      cell: new EmailCell(),
      key: 'email',
      weight: 2,
      text: 'gesuchverlauf.table.header.email'
    })
  }

  static ERGEBNIS() {
    return new DropdownMultiselectColumn({
      key: 'freigabestatus',
      sortable: true,
      weight: 2,
      text: 'gesuchverlauf.table.header.ergebnis',
      items: Freigabestatus.ITEMS
    })
  }

  static AKTIONEN(pdfDownloadCallback) {
    return new GenericColumn({
      cell: new AktionenCell({
        buttons: [
          {
            text: 'gesuchverlauf.table.pdf.download',
            icon: 'download',
            callback: pdfDownloadCallback
          }
        ]
      }),
      sortable: false,
      text: 'gesuchverlauf.table.header.aktionen'
    })
  }
}

import {css} from '@isceco/widget-library2/external/lit'

export default css`
  :host {
    display: block;
  }

  svg {
    width: 100%;
  }

  .transparent {
    fill: transparent;
    stroke: none;
  }

  .hover.wohnanlage {
    stroke: var(--color-wohnanlage);
  }

  .hover.wohnstandort {
    stroke: var(--color-wohnstandort);
  }

  .hover.wohnung {
    stroke: var(--color-wohnung);
  }

  .hover {
    stroke-width: 0;
  }

  .hover:hover {
    stroke-width: 2;
  }

  #mask {
    fill: white;
  }
`

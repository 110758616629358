export default class Nutzungsanliegen {
  // @alican Object freeze und als const
  static get KEYS() {
    return {
      PLANEN: 1,
      BEURTEILEN: 2,
      VERGLEICHEN: 3,
      FOERDERN: 4
    }
  }

  static get ITEMS() {
    return Object.values(Nutzungsanliegen.KEYS)
      .map(value => ({
        value: value,
        name: `nutzungsanliegen.${value}`
      }))
  }
}

import '@isceco/widget-library2/basic-elements/Button/Button.js'
import BerechtigungService from '../Berechtigung/BerechtigungService.js';

export default class AnzahlGeteiltMitCell {
  render(i18n, cell, row) {
    const displayElement = document.createElement('span')
    const berechtigungService = new BerechtigungService(row.id)
    berechtigungService.read('geteilte', '', true).then(resp => {
      if (resp.ok) {
        resp.text().then(anzahl => {
          displayElement.innerText = anzahl
        })
      }
    })
    return displayElement
  }
}

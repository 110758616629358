import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/SingleselectFilter.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/RadioGroup/RadioGroup.js'
import BerechtigungService from './BerechtigungService.js'
import BerechtigungDTO from './BerechtigungDTO.js'
import BerechtigungColumns from './BerechtigungColumns.js'
import MainWebComponent from '../../MainWebComponent.js'

export default class Berechtigung extends MainWebComponent {
  get translationFile() {
    return './views/Berechtigung/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.currentBerechtigung = {}
    this.wohnobjektId = getNavigationId()
    this.updateBerechtigungTable = 'update-berechtigung-table-event'
    this.url = ''
    this.reload()
  }

  reload() {
    const berechtigungService = new BerechtigungService(this.wohnobjektId)
    berechtigungService.getBackendListUrl()
      .then(url => {
        this.url = url
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    const submitText = this.isEdit() ? 'berechtigung.form.speichern' : 'berechtigung.form.hinzufuegen'
    return html`
      <isceco-title size="medium" text="${this.i18n.translate('view.header')}">
        <isceco-button id="berechtigung-erstellen"
                       text="${this.i18n.translate('berechtigung.hinzufuegen')}"
                       variant="tertiary"
                       icon="plus"
                       size="small"
                       @click="${_ => this.openModalForm(null)}">
        </isceco-button>
      </isceco-title>
      <isceco-filter-group>
        <isceco-string-filter minchars="0"
                              label="${this.i18n.translate('table.header.email')}"
                              key="email">
        </isceco-string-filter>
        <isceco-singleselect-filter
          label="${this.i18n.translate('table.header.type')}"
          .items="${this.getTypeOptions()}"
          key="type">
        </isceco-singleselect-filter>
      </isceco-filter-group>
      <isceco-list id="berechtigung-table"
                   update-event="${this.updateBerechtigungTable}"
                   url="${this.url}"
                   max="5"
                   sort="email"
                   order="asc"
                   .i18n="${this.i18n}"
                   .dtoMapper="${this.toBerechtigung}"
                   .columns="${BerechtigungColumns.getColumns(this.requestDeleteBerechtigung, this.openModalForm)}"
      >
      </isceco-list>
      <isceco-dialog hidden
                     id="berechtigung-loeschen-dialog"
                     header="${this.i18n.translate('berechtigung.loeschen.dialog.header')}"
                     confirm-button="${this.i18n.translate('berechtigung.loeschen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('berechtigung.loeschen.dialog.button.cancel')}"
                     @submit="${e => this.deleteBerechtigung(e)}"
      >
      </isceco-dialog>
      <isceco-dialog hidden
                     id="berechtigung-form-dialog"
                     header="${this.i18n.translate('berechtigung.form.dialog.header')}"
                     confirm-button="${this.i18n.translate(submitText)}"
                     cancel-button="${this.i18n.translate('berechtigung.form.dialog.button.cancel')}"
                     @submit="${e => this.submitFormDialog(e)}"
      >
        <isceco-form id="berechtigung-form"
                     @submit="${e => this.submitCallback(e.detail)}">
          <div slot="form-elements" class="form">
            <isceco-text-input required
                               label="${this.i18n.translate('berechtigung.form.email')}"
                               type="email"
                               name="email"
                               pattern-error-text="${this.i18n.translate('berechtigung.form.email.error.text')}"
            ></isceco-text-input>
            <isceco-radio-group label="${this.i18n.translate('berechtigung.form.berechtigung')}"
                                required
                                name="type"
                                .items="${[
                                  {value: 1, name: this.i18n.translate('berechtigung.type.1')},
                                  {value: 2, name: this.i18n.translate('berechtigung.type.2')}
                                ]}"
            ></isceco-radio-group>
          </div>
        </isceco-form>
      </isceco-dialog>
    `
  }

  submitFormDialog(e) {
    if (e.detail.confirmed) {
      this.querySelector('#berechtigung-form')._submit()
    } else {
      e.target.hidden = true
    }
  }

  isEdit() {
    return !isEmpty(this.currentBerechtigung.id) && this.currentBerechtigung.id !== 0
  }

  toBerechtigung = row => new BerechtigungDTO(row)

  requestDeleteBerechtigung = row => {
    this.currentBerechtigung = row
    const dialog = document.getElementById('berechtigung-loeschen-dialog')
    dialog.description = this.i18n.translate('berechtigung.loeschen.dialog.description', {email: this.currentBerechtigung.email})
    dialog.hidden = false
  }

  deleteBerechtigung = event => {
    if (event.detail.confirmed) {
      const berechtigungService = new BerechtigungService(this.wohnobjektId)
      berechtigungService.delete(this.currentBerechtigung.id)
        .then(_ => {
          showAlert(
            this.i18n.translate('berechtigung.delete.success.title'),
            this.i18n.translate('berechtigung.delete.success.body', {email: this.currentBerechtigung.email}),
            'success'
          )
          send(this.updateBerechtigungTable)
        })
    }
    event.target.hidden = true
  }

  submitCallback(berechtigung) {
    const berechtigungService = new BerechtigungService(this.wohnobjektId)
    this.currentBerechtigung.type = parseInt(this.currentBerechtigung.type)
    if (this.isEdit()) {
      berechtigung.id = this.currentBerechtigung.id
      berechtigungService.update(berechtigung).then(() => {
        showAlert(
          this.i18n.translate('berechtigung.update.success.title'),
          this.i18n.translate('berechtigung.update.success.body', {email: this.currentBerechtigung.email}),
          'success'
        )
        send(this.updateBerechtigungTable)
      })
    } else {
      berechtigungService.create(berechtigung).then(() => {
        showAlert(
          this.i18n.translate('berechtigung.create.success.title'),
          this.i18n.translate('berechtigung.create.success.body', {email: berechtigung.email}),
          'success'
        )
        send(this.updateBerechtigungTable)
      })
    }
    this.querySelector('#berechtigung-form-dialog').hidden = true
  }

  getTypeOptions() {
    return Array.from({length: 2}, (_, i) => ({
      value: `${i + 1}`,
      name: this.i18n.translate(`berechtigung.type.${i + 1}`)
    }))
  }

  openModalForm = row => {
    const emailField = this.querySelector('*[name=\'email\']')
    if (isEmpty(row)) {
      this.currentBerechtigung = {}
      emailField.disabled = false
    } else {
      this.currentBerechtigung = row
      emailField.disabled = true
    }
    this.querySelector('#berechtigung-form').getFormElements(true).forEach(element => {
      element.value = this.currentBerechtigung[element.getAttribute('name')]
      element.classList.remove('error')
      element.requestUpdate()
    })
    this.querySelector('#berechtigung-form-dialog').hidden = false
  }
}
customElements
  .define(
    'tool-frontend-berechtigung'
    ,
    Berechtigung
  )

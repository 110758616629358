import {html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js';
import Navigation from './Navigation.js';
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js';

export default class HorizontalNavigation extends WebComponent {

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => {
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {
    const authorizationFilter = new AuthorizationFilter()
    const items = [
      {
        text: this.i18n.translate('navigation.wohnobjekt'),
        url: 'Wohnobjekt',
        suburls: [
          'Berechtigung',
          'GesuchEinreichung',
          'GesuchEntscheidung',
          'GesuchVerlauf',
          'Kriterium',
          'Vorbereitung',
          'WohnanlageEdit',
          'WohnobjektDetail',
          'WohnobjektEdit',
          'WohnungenEdit'
        ],
        active: true
      },
      {
        text: this.i18n.translate('navigation.kontakt'),
        url: 'Kontakt'
      },
      {
        text: this.i18n.translate('navigation.gesuche'),
        url: 'Gesuche'
      },
      {
        text: this.i18n.translate('navigation.auswertungen'),
        url: 'Auswertungen'
      }
    ]
      .filter(i => authorizationFilter.viewAllowed(i.url))

    return html`
      <isceco-horizontal-navigation .items="${items}">
      </isceco-horizontal-navigation>
    `
  }
}
customElements.define('tool-frontend-horizontal-navigation', HorizontalNavigation)

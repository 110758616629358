import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Navigation from '../Navigation/Navigation.js'
import NoContent from '../NoContent/NoContent.js'
import Wohnobjekt from '../Wohnobjekt/Wohnobjekt.js'
import NotAuthorized from '../NotAuthorized/NotAuthorized.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import WohnobjektEdit from '../WohnobjektEdit/WohnobjektEdit.js'
import WohnobjektDetail from '../WohnobjektDetail/WohnobjektDetail.js'
import Kriterium from '../Kriterium/Kriterium.js'
import WohnanlageEdit from '../WohnanlageEdit/WohnanlageEdit.js'
import WohnungenEdit from '../WohnungenEdit/WohnungenEdit.js';
import Kontakt from '../Kontakt/Kontakt.js';
import Berechtigung from '../Berechtigung/Berechtigung.js';
import GesuchEinreichung from '../GesuchEinreichung/GesuchEinreichung.js';
import Gesuche from '../Gesuche/Gesuche.js'
import GesuchVerlauf from '../GesuchVerlauf/GesuchVerlauf.js';
import GesuchEntscheidung from '../GesuchEntscheidung/GesuchEntscheidung.js'
import Vorbereitung from '../Vorbereitung/Vorbereitung.js'
import Auswertungen from '../Auswertungen/Auswertungen.js'

export default class MainView extends WebComponent {

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null
    this.navigationListener = e => {
      this.loadView(e.detail)
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.loadView(getNavigationHash())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  loadView(linkName) {
    if (isEmpty(linkName)) {
      this.redirectToDefault()
    }
    const newChild = this.returnNewChild(linkName)
    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }

    this.oldChild = newChild
  }

  returnNewChild(linkName) { //NOSONAR
    if (!new AuthorizationFilter().viewAllowed(linkName)) {
      return new NotAuthorized()
    }
    switch (linkName) {
      case 'Berechtigung': return new Berechtigung()
      case 'Gesuche': return new Gesuche()
      case 'GesuchEinreichung': return new GesuchEinreichung()
      case 'GesuchEntscheidung': return new GesuchEntscheidung()
      case 'GesuchVerlauf': return new GesuchVerlauf()
      case 'Kontakt': return new Kontakt()
      case 'Kriterium': return new Kriterium()
      case 'Vorbereitung': return new Vorbereitung()
      case 'WohnanlageEdit': return new WohnanlageEdit()
      case 'Wohnobjekt': return new Wohnobjekt()
      case 'WohnobjektDetail': return new WohnobjektDetail()
      case 'WohnobjektEdit': return new WohnobjektEdit()
      case 'WohnungenEdit': return new WohnungenEdit()
      case 'Auswertungen': return new Auswertungen()
      default: return new NoContent()
    }
  }

  redirectToDefault() {
    navigate({to: 'Wohnobjekt'})
  }

  getTemplate() {
    return html`tool-frontend-main-view`
  }
}

customElements.define('tool-frontend-main-view', MainView)
